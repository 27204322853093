import {IRiskItem} from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, QuestionnaireResponse} from "resources/classes/FhirModules/Fhir";
import {AnalyzerClass} from '../AnalyzerClass';
import {NitTools} from 'resources/classes/NursitTools';
import {IQuestionnaireList, QuestionnaireService} from '../QuestionnaireService';
import {fhirEnums} from "../../classes/fhir-enums";
import {AnalyzeService} from '../analyzeService';
import {ConfigService} from "../ConfigService";
import {Epa2_3Nrs} from "./epa2_3_Subs/epa2_3-nrs";
import {PatientService} from "../PatientService";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {PatientItem} from "../../classes/Patient/PatientItem";

export class AnalyzerEpaPSYC extends AnalyzerClass {
    ///// START IMPLEMENTATION/OVERLOADS HERE //////////////////

    _version = "epaPSYC";
    description: string = "This analyzer epa PSYC, derived from epa AC 2.3";
    needToStoreFlags: boolean = true;

    //EPA2.3 functions
    /** BRADEN */
    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let sum = 0;

        //#region Positionswechsel im Liegen, E2_I_167
        let value = String(QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_167'));
        switch (value) {
            case 'E3_I_0682':
                sum += 4;
                break;
            case 'E3_I_0683':
                sum += 3;
                break;
            case 'E3_I_0684':
                sum += 2;
                break;
            case 'E3_I_0685':
                sum += 1;
                break;
        }
        //#endregion

        //#region Fortbewegung, E2_I_001
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001');
        switch (value) {
            case 'E3_I_0004':
                sum += 4;
                break;
            case 'E3_I_0003':
                sum += 3;
                break;
            case 'E3_I_0002':
                sum += 2;
                break;
            case 'E3_I_0001':
                sum += 1;
                break;
        }
        //#endregion

        //#region Reibung / Scherkräfte bei Positionswechsel / Transfer, E2_I_003
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_003');
        switch (value) {
            case 'E3_I_0013':
                sum += 3;
                break;
            case 'E3_I_0012':
                sum += 2;
                break;
            case 'E3_I_0011':
                sum += 1;
                break;
        }
        //#endregion

        //#region Nahrungsmenge (gesamt), E2_I_015
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_015');
        switch (value) {
            case 'E3_I_0206':
                sum += 4;
                break;
            case 'E3_I_0205':
                sum += 3;
                break;
            case 'E3_I_0204':
                sum += 2;
                break;
            case 'E3_I_0203':
                sum += 1;
                break;
        }
        //#endregion

        //#region Feuchtigkeit der Haut, E2_I_031
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_031');
        switch (value) {
            case 'E3_I_0222':
                sum += 4;
                break;
            case 'E3_I_0221':
                sum += 3;
                break;
            case 'E3_I_0220':
                sum += 2;
                break;
            case 'E3_I_0219':
                sum += 1;
                break;
        }
        //#endregion

        //#region Sensorische Wahrnehmung, E2_I_048
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_048');
        switch (value) {
            case 'E3_I_0181':
                sum += 4;
                break;
            case 'E3_I_0180':
                sum += 3;
                break;
            case 'E3_I_0179':
                sum += 2;
                break;
            case 'E3_I_0178':
                sum += 1;
                break;
        }
        //#endregion

        //#region Auswertung:
        // https://nursiti.plan.io/issues/1560:
        // (..) Stufen sind standardmäßig wie folgt definiert: =< 12 = hohes Risiko 13-16 = erhöhtes Risiko > 16 = Risiko unwahrscheinlich (..)
        if (sum <= 12) { // hohes risiko
            sum = 2;
        } else if (sum >= 13 && sum <= 16) { // erhöhtes Risiko
            sum = 1;
        } else if (sum > 16) { // unwarscheinlich
            sum = 0;
        } else sum = -1;

        let text = this.i18n.tr("not_available");
        switch (sum) {
            default:
            case -1:
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
            case 2:
                text = this.i18n.tr("risk_high");
                break;
        }

        //#endregion

        this.braden = {
            sum: sum,
            field: 'risk_deku',
            text: text
        };

        return this.braden;
    }

    public braden = {
        sum: NaN,
        field: 'risk_deku',
        text: ''
    };

    /** Experteneinschätzung: weitere Dekubitus Risikoindikatoren */
    public calculateExpertReviewRiskDecu(patient: PatientItem, assessment?: any): { isExpertRisk: boolean, reason: string } {
        if (!assessment) assessment = patient.latestAssessment;

        //#region Experteneinschätzung: weitere Dekubitus-Risikoindikatoren, E0_I_0266
        const value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E0_I_0266'); // Experteneinschätzung: weitere Dekubitus-Risikoindikatoren
        const result = {isExpertRisk: false, reason: undefined};
        switch (value) {
            case 'E0_I_0267': // Nein
                result.isExpertRisk = false;
                break;
            case 'E0_I_0268': // Ja
                result.isExpertRisk = true;
                break;
        }
        result.reason = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E0_I_0269'); // Begründung Experte
        //#endregion

        this.expertRiskDecu.isExpertRisk = result.isExpertRisk;
        this.expertRiskDecu.reason = result.reason;

        return result;
    }

    public expertRiskDecu = {
        isExpertRisk: false,
        reason: ''
    };

    public calculateFall(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let isRisk = false;

        let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001'); // Fortbewegung
        switch (value) {
            case 'E3_I_0001':
            case 'E3_I_0002':
                isRisk = true;
                break;
            case 'E3_I_0003':
                isRisk = patient.years >= 65;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_004'); // verändertes gangbild
        isRisk = isRisk || value === 'E3_I_0019';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_005'); // Gleichgewichtsstöhrungen
        isRisk = isRisk || value === 'E3_I_0022';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_163'); // Sturzvorgeschichte
        isRisk = isRisk || value === 'E3_I_0664';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_164'); // Aktuelles Sturzereignis
        isRisk = isRisk || value === 'E3_I_0666';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_025'); // Urinausscheidung kontrollieren
        isRisk = isRisk || (
            (value === 'E3_I_0074') ||
            (value === 'E3_I_0073' && QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_029' /*Urinableitungssystem*/) === 'E3_I_0091')
            // E3_I_0073 && E2_I_029:E3_I_0091 => Pfützenbildung weil kann Urin nicht halten aber kein ableitesystem
        );

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_027');
        isRisk = isRisk || (value === 'E3_I_0084' ||
            (value === 'E3_I_0083' && QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_030' /*Stuhlableitungssystem*/) === 'E3_I_0094')
            // Grund->siehe Urin
        );

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_028'); // Dranghafte/ gesteigerte Ausscheidung
        isRisk = isRisk || value === 'E3_I_0089'; // Ja

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_033'); // Orientierung (Person, Ort, Zeit, Situation)
        isRisk = isRisk || value === 'E3_I_0127' || value === 'E3_I_0126';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_053'); // Sturz-/ Delir-Risiko erhöhende Medikamente
        isRisk = isRisk || value === 'E3_I_0290';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_062'); // Sehen
        isRisk = isRisk || value === 'E3_I_0269' || value === 'E3_I_0268';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_041'); // Einschlafen/ Durchschlafen
        isRisk = isRisk || value === 'E3_I_0161';

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_042'); // Schlaf-Wach-Rhythmus
        isRisk = isRisk || value === 'E3_I_0164';

        let textValue: string; // = this.i18n.tr("n_a");
        if (isRisk) {
            textValue = this.i18n.tr("increased_risk");
        } else {
            textValue = this.i18n.tr("risk_unlikely");
        }

        return {
            text: textValue,
            isRisk: isRisk,
            field: 'risk_sturz'
        };
    }

    /***
     * Vormals Abklärungserfordernis Verwirrtheit, Delir, Demenz (VDD).\n
     Es wird ein Abklärungserfordernis ausgewiesen, sobald mindestens ZWEI aufgeführte Triggeritems mit den dargestellten Werten angegeben werden.
     * @param patient
     * @param assessment
     */
    public calculateNCD(patient: PatientItem, assessment?: any) {
        /*  */

        let sum = 0;  // aka n/a
        let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_033'); // Orientierung (Person, Ort, Zeit, Situation)
        switch (value) {
            case 'E3_I_0127': // 2: zu einer oder zwei Qualitäten
            case 'E3_I_0126': // 1: zu keiner Qualität
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_082'); // Informationen verarbeiten/ verstehen
        switch (value) {
            case 'E3_I_0388': // 2: gering vorhandene Fähigkeit
            case 'E3_I_0387': // 1: keine Fähigkeit
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_069'); // Alltagskompetenz
        switch (value) {
            case 'E3_I_0255': // 2: gering vorhandene Fähigkeit
            case 'E3_I_0254': // 1: keine Fähigkeit
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_054'); // Aufmerksamkeit
        switch (value) {
            case 'E3_I_0412': // 2: zeitweise beeinträchtigt
            case 'E3_I_0257': // 1: kontinuierlich beeinträchtigt
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_053'); // Sturz-/ Delir-Risiko erhöhende Medikamente
        switch (value) {
            case 'E3_I_0290': // 1: Ja
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_064'); // Selbst initiierte Aktivitäten
        switch (value) {
            case 'E3_I_0277': // 2: wenige selbst initiierte Aktivitäten
            case 'E3_I_0276': // 1: keine selbst initiierten Aktivitäten
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_065'); // Merkmale herausfordernden Verhaltens
        switch (value) {
            case 'E3_I_0260': // 1a: agitiert nicht aggressives Verhalten (körperl./ verbal)
            case 'E3_I_0261': // 1b: agitiert aggressives Verhalten (körperl./ verbal)
            case 'E3_I_0243': // 1c: kombiniert aggressiv und nicht aggressiv agitiertes Verhalten
            case 'E3_I_0262': // 1d: passives/ apathisches Verhalten
                sum += 1;
                break;
        }

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_042');  // Schlaf-Wach-Rhythmus
        if (value === 'E3_I_0164') sum += 1; // 1: verändert

        this.ncd.text = this.i18n.tr(sum === 0 ? 'risk_unlikely' : 'risk_high');
        this.ncd.isRisk = sum >= 2;

        return this.ncd;
    }

    public ncd = {
        text: '',
        isRisk: false,
        field: 'risk_vdd'
    };

    public calculateVdd(patient: PatientItem, assessment?: any) {
        return this.calculateNCD(patient, assessment);
    }

    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;

        //#region E2_I_024, Urinausscheidung durchführen 1,2,3,4
        let SID_04_01 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_024');
        switch (SID_04_01) {
            case 'E3_I_0071':
                SID_04_01 = 4;
                break;
            case 'E3_I_0070':
                SID_04_01 = 3;
                break;
            case 'E3_I_0069':
                SID_04_01 = 2;
                break;
            case 'E3_I_0068':
                SID_04_01 = 1;
                break;
        }
        //#endregion

        //#region E2_I_025, Urinausscheidung kontrollieren 1,2,3,4
        let SID_04_02 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_025');
        switch (SID_04_02) {
            case 'E3_I_0076':
                SID_04_02 = 4;
                break;
            case 'E3_I_0075':
                SID_04_02 = 3;
                break;
            case 'E3_I_0074':
                SID_04_02 = 2;
                break;
            case 'E3_I_0073':
                SID_04_02 = 1;
                break;
        }
        //#endregion

        //#region E2_I_029, Urinableitungssystem 1,4
        let SID_04_09 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_029');
        switch (SID_04_09) {
            case 'E3_I_0091':
                SID_04_09 = 4;
                break;
            default:
            case 'E3_I_0092':
                SID_04_09 = 1;
                break;
        }
        //#endregion

        //#region E2_I_195 : [choice] : "Adhärenz"
        let SID_34_16 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_195');
        switch (SID_34_16) {
            case 'E3_I_0789':
                SID_34_16 = 4;
                break;
            case 'E3_I_0790':
                SID_34_16 = 3;
                break;
            case 'E3_I_0791':
                SID_34_16 = 2;
                break;
            case 'E3_I_0792':
                SID_34_16 = 1;
                break;
        }
        //#endregion

        //#region E2_I_174 : [choice] : "Antrieb"
        let SID_31_20 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_174');
        switch (SID_31_20) {
            case 'E3_I_0708':
                SID_31_20 = 4;
                break;
            case 'E3_I_0709':
                SID_31_20 = 11;
                break;
            case 'E3_I_0710':
                SID_31_20 = 12;
                break;
        }
        //#endregion

        let inkontinenzText = this.i18n.tr("n_a");
        let contincenceValue = -1;
        if (SID_04_01 === 4 && SID_04_02 === 4 && SID_04_09 === 4 && SID_34_16 && SID_31_20) {
            inkontinenzText = this.i18n.tr("kontinenz");
            contincenceValue = 7;
        } else if (SID_04_01 === 4 && SID_04_02 === 4 && (SID_04_09 === 4 || SID_04_09 === 1) && SID_34_16 && SID_31_20) {
            inkontinenzText = this.i18n.tr("unabhaengigerreichtekontinenz");
            contincenceValue = 8;
        } else if (SID_04_01 != 4 && SID_04_02 === 4 && (SID_04_09 === 4 || SID_04_09 === 1) && SID_34_16 && SID_31_20) {
            inkontinenzText = this.i18n.tr("abhaengigerreichtekontinenz");
            contincenceValue = 9;
        } else if (SID_04_01 === 4 && SID_04_02 != 4 && (SID_04_09 === 4 || SID_04_09 === 1) && SID_34_16 && SID_31_20) {
            inkontinenzText = this.i18n.tr("unabhaengigkompensierteinkontinenz");
            contincenceValue = 10;
        } else if (SID_04_01 != 4 && SID_04_02 != 4 && (SID_04_09 === 4 || SID_04_09 === 1) && (SID_34_16 === 4 || SID_34_16 === 3) && SID_31_20) {
            inkontinenzText = this.i18n.tr("abhaengigkompensierteinkontinenz");
            contincenceValue = 11;
        } else if (SID_04_01 != 4 && SID_04_02 != 4 && (SID_04_09 === 4 || SID_04_09 === 1) && (SID_34_16 === 2 || SID_34_16 === 1) && (SID_31_20 === 11 || SID_31_20 === 12)) {
            inkontinenzText = this.i18n.tr("nichtkompensierteinkontinenz");
            contincenceValue = 12;
        }

        this.continence = {
            field: 'kontinenz',
            text: inkontinenzText,
            value: contincenceValue
        };

        return this.continence;
    }

    public continence = {
        field: 'kontinenz',
        text: '',
        value: -1
    };

    public calculatePneumo(patient: PatientItem, assessment?: any) {
        let sum = 0;
        let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001'); // Fortbewegung
        if (value === 'E3_I_0002' || value === 'E3_I_0001')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_022'); // Schluckstörungen
        if (value === 'E3_I_0066')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_032'); // Bewusstsein/ Vigilanz
        if (value === 'E3_I_0123' || value === 'E3_I_0122')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_043'); // Atmung beeinträchtigt (aktuell)
        if (value === 'E3_I_0167')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_044'); // Chron. Erkrankung des Atmungssystems
        if (value === 'E3_I_0169')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_045'); // Beatmung > 24h akt. Aufenthalt
        if (value === 'E3_I_0172')
            sum += 1;

        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_068'); // Tracheostoma
        if (value === 'E3_I_0175')
            sum += 1;

        return {
            text: this.i18n.tr(sum === 0 ? "risk_unlikely" : "increased_risk"),
            field: 'risk_pneu',
            sum: sum,
            isRisk: sum >= 1
        };
    }

    public calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList, spi: number) {
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        let formSettings = ConfigService.GetFormSettings('analyse');
        let epa23nrs = new Epa2_3Nrs(patient, assessment, anamnesis, formSettings, spi, this.i18n);
        const nrsResult = epa23nrs.go();

        try {
            if (!assessment) assessment = patient.latestAssessment;
            if (!assessment) {
                let msg = "No Assessment for Patient found";
                console.warn(msg);

                return undefined;
            }

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_sum", true);
            item.answer = [{valueInteger: nrsResult.riskSum}];

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
            if (item) item.answer = [{valueInteger: nrsResult.errorResult}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, 0);

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_warning", true);
            if (item) item.answer = [{valueString: nrsResult.riskText}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, this.i18n.tr("not_available"));

            return nrsResult;
        } catch (error) {
            console.warn(error.message);
            return undefined;
        }
    }

    public calculateBarthelIndex(patient: PatientItem, assessment: any, biResponse: any): number {
        try {
            let formSum = 0;
            if (biResponse.status !== QuestionnaireResponseStatus.inProgress) {
                formSum = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(biResponse, 'BI_11'));
            } else {
                let q = QuestionnaireService.GetQuestionnaireDirect(biResponse.questionnaire);
                biResponse.item = [];
                Fhir.Questionnaire.EnsureStructuredResponse(q, biResponse);
                // Fhir.Questionnaire.SetDefaultValues(q, biResponse, null);

                //#region BI_01, Essen 10,5,0
                const E2_I_013 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_013'); // essen
                const E2_I_067 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_067'); /// Sondennahrung
                if (E2_I_013 === 'E3_I_0029') {
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_01', 'BI_01_10', '10');
                    formSum += 10;
                } else if (
                    ((E2_I_013 === 'E3_I_0028' || E2_I_013 === 'E3_I_0027' || E2_I_013 === 'E3_I_0026') && E2_I_067 === 'E3_I_0248')
                    || (E2_I_013 === 'E3_I_0028' && E2_I_067 === 'E3_I_0249')
                ) {
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_01', 'BI_01_05', '5');
                    formSum += 5;
                } else if ((E2_I_013 === 'E3_I_0027' || E2_I_013 === 'E3_I_0026') && E2_I_067 === 'E3_I_0249')
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_01', 'BI_01_00', '0');
                //#endregion

                //#region BI_02, Aufsetzen/Umsetzen  15,10,5,0
                const E2_I_169 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_169'); // Transfer
                let bi02: number = NaN;
                switch (E2_I_169) {
                    case 'E3_I_0690':
                        bi02 = 15;
                        break;
                    case 'E3_I_0691':
                        bi02 = 10;
                        break;
                    case 'E3_I_0692':
                        bi02 = 5;
                        break;
                    case 'E3_I_0693':
                        bi02 = 15;
                        break;
                }
                if (!isNaN(bi02)) {
                    formSum += bi02;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_02', 'BI_02_' + this.makeTwo(bi02), String(bi02));
                }
                //#endregion

                //#region BI_03, Sich waschen, 0,5
                const E2_I_009 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_009');
                if (E2_I_009 === 'E3_I_0105') {
                    formSum += 5;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_03', 'BI_03_05', '5');
                } else if (['E3_I_0104', 'E3_I_0103', 'E3_I_0102'].indexOf(E2_I_009) > -1) {
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_03', 'BI_03_00', '0');
                }
                //#endregion

                //#region BI_04, Toilettenbenutzung, 10,5,0
                const E2_I_026 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_026');
                let bi04: number = NaN;
                switch (E2_I_026) {
                    case 'E3_I_0081':
                        bi04 = 10;
                        break;
                    case 'E3_I_0080':
                    case 'E3_I_0079':
                        bi04 = 5;
                        break;
                    case 'E3_I_0078':
                        bi04 = 0;
                        break;
                }
                if (!isNaN(bi04)) {
                    formSum += bi04;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_04', 'BI_04_' + this.makeTwo(bi04), String(bi04));
                }
                //#endregion

                //#region BI_05, Baden/Duschen, 5,0
                const E2_I_010 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_010');
                let bi05: number = NaN;
                switch (E2_I_010) {
                    case 'E3_I_0110':
                        bi05 = 5;
                        break;
                    case 'E3_I_0109':
                    case 'E3_I_0108':
                    case 'E3_I_0107':
                        bi05 = 0;
                        break;
                }
                if (!isNaN(bi05)) {
                    formSum += bi05;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_05', 'BI_05_' + this.makeTwo(bi05), String(bi05));
                }
                //#endregion

                //#region BI_06, Auftshen und Gehen, 15,10,5,0
                let bi06: number = NaN;
                const anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                const E2_I_001 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001');
                const E0_I_009 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'E0_I_009');
                if ((E2_I_001 === 'E3_I_0004' && (E0_I_009 === 'E0_I_0002' || E0_I_009 === 'E0_I_0155'))) {
                    bi06 = 15;
                } else if (E2_I_001 === 'E3_I_0004' && E0_I_009 === 'E0_I_0156')
                    bi06 = 10;
                else if ((E2_I_001 === 'E3_I_0004' && E0_I_009 === 'E0_I_0157')
                    || (E2_I_001 === 'E3_I_0003' && (['E0_I_0002', 'E0_I_0155', 'E0_I_0156'].indexOf(E0_I_009) > -1)))
                    bi06 = 5;
                else if ((E2_I_001 === 'E3_I_0002' || E2_I_001 === 'E3_I_0001')
                    || (E2_I_001 === 'E3_I_0003' && E0_I_009 === 'E0_I_0157'))
                    bi06 = 0;

                if (!isNaN(bi06)) {
                    formSum += bi06;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_06', 'BI_06_' + this.makeTwo(bi06), String(bi06));
                }
                //#endregion

                //#region BI_07, Treppen, 10,5,0
                let bi07: number = NaN;
                switch (E2_I_001) {
                    case 'E3_I_0004':
                        bi07 = 10;
                        break;
                    case 'E3_I_0003':
                        bi07 = 5;
                        break;
                    case 'E3_I_0002':
                    case 'E3_I_0001':
                        bi07 = 0;
                        break;
                }

                if (!isNaN(bi07)) {
                    formSum += bi07;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_07', 'BI_07_' + this.makeTwo(bi07), String(bi07));
                }
                //#endregion

                //#region BI_08, An- und Auskleiden, 10,5,0
                const E2_I_011 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_011'); // values: E3_I_0115=4, E3_I_0114=3, E3_I_0113=2, E3_I_0112=1
                const E2_I_012 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_012'); // values: E3_I_0120=4, E3_I_0119=3 ,E3_I_0118=2, E3_I_0117=1
                let IntE2_I_011 = 0;
                switch (E2_I_011) {
                    case 'E3_I_0115':
                        IntE2_I_011 = 4;
                        break;
                    case 'E3_I_0114':
                        IntE2_I_011 = 3;
                        break;
                    case 'E3_I_0113':
                        IntE2_I_011 = 2;
                        break;
                    case 'E3_I_0112=1':
                        IntE2_I_011 = 1;
                        break;
                }

                let IntE2_I_012 = 0;
                switch (E2_I_012) {
                    case 'E3_I_0120':
                        IntE2_I_012 = 4;
                        break;
                    case 'E3_I_0119':
                        IntE2_I_012 = 3;
                        break;
                    case 'E3_I_0118':
                        IntE2_I_012 = 2;
                        break;
                    case 'E3_I_0117':
                        IntE2_I_012 = 1;
                        break;
                }

                let bi08: number = NaN;
                const sumE2_11_12 = IntE2_I_012 + IntE2_I_011;
                if (E2_I_011 === 'E3_I_0115' && E2_I_012 === 'E3_I_0120') {
                    bi08 = 10;
                } else if (sumE2_11_12 > 4 && sumE2_11_12 < 8) {
                    bi08 = 5;
                } else if (sumE2_11_12 < 5 && sumE2_11_12 > 0) {
                    bi08 = 0;
                }

                if (!isNaN(bi08)) {
                    formSum += bi08;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_08', 'BI_08_' + this.makeTwo(bi08), String(bi08));
                }
                //#endregion

                //#region BI_09, Stuhlkontinenz, 10,5,0
                const E2_I_027 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_027'); // values: E3_I_0086, E3_I_0085, E3_I_0084, E3_I_0083
                // const E2_I_026 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_026'); // values: E3_I_0081, E3_I_0080, E3_I_0079, E3_I_0078
                const E2_I_030 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_030'); // values: E3_I_0094, E3_I_0095, E2_I_028, E3_I_0088, E3_I_0089
                let bi09: number = NaN;
                if ((E2_I_027 === 'E3_I_0086' && E2_I_030 === 'E3_I_0094')
                    || (E2_I_026 === 'E3_I_0081' && E2_I_030 === 'E3_I_0095'))
                    bi09 = 10;
                else if ((E2_I_027 === 'E3_I_0085' && E2_I_030 === 'E3_I_0094')
                    || ((['E3_I_0080', 'E3_I_0079', 'E3_I_0078'].indexOf(E2_I_026) > -1) && E2_I_030 === 'E3_I_0095'))
                    bi09 = 5;
                else if (((E2_I_027 === 'E3_I_0084' || E2_I_027 === 'E3_I_0083') && E2_I_030 === 'E3_I_0094')) {
                    bi09 = 0;
                }

                if (!isNaN(bi09)) {
                    formSum += bi09;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_09', 'BI_09_' + this.makeTwo(bi09), String(bi09));
                }
                //#endregion

                //#region BI_10, Harnkontinenz, 10,5,0
                const E2_I_025 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_025');
                let bi10: number = NaN;
                switch (E2_I_025) {
                    case 'E3_I_0076':
                        bi10 = 10;
                        break;
                    case 'E3_I_0075':
                        bi10 = 5;
                        break;
                    case 'E3_I_0074':
                    case 'E3_I_0073':
                        bi10 = 0;
                        break;
                }

                if (!isNaN(bi10)) {
                    formSum += bi10;
                    QuestionnaireResponse.SetResponseItemCodingByLinkId(biResponse, 'BI_10', 'BI_10_' + this.makeTwo(bi10), String(bi10));
                }
                //#endregion

                //#region BI_11 -> Sum 1-10
                let bi11Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, "BI_11", false);
                if (bi11Item) Fhir.QuestionnaireResponse.SetResponseItemIntByLinkId(biResponse, 'BI_11', formSum);

                bi11Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "BI_11", false);
                if (bi11Item) Fhir.QuestionnaireResponse.SetResponseItemIntByLinkId(assessment, 'BI_11', formSum);
                //#endregion
            }

            PatientService.AddQuestionnaireResponse(patient, biResponse, true);

            return formSum;
        } catch (e) {
            console.warn("Could not calculate BI because of error: " + e.message);
            return NaN;
        }
    }

    public calculateBartheldIndexEx(patient: PatientItem, assessment: any, anamnesis: any, biExResponse: any): number {
        //#region do some checking ....
        if (!assessment) {
            let msg = "No Assessment. Aborting";
            console.warn(msg);
            return NaN;
        }

        if (!biExResponse) {
            let msg = "No BI-Ex Document. Aborting";
            console.warn(msg);
            return NaN;
        }

        if (biExResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
            let msg = "BiEx-Status is not in-progress. Returning stored value..";
            console.warn(msg);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, "EBI_07");
            if (item) {
                let ebi07Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                let int = parseInt(ebi07Value);
                return int;
            } else {
                return NaN;
            }
        }
        //#endregion

        //#region ... then perform the calulcation ...
        // ensure a structural correct response with all answers:
        let biExquestionnaire = QuestionnaireService.GetQuestionnaireDirect(biExResponse.questionnaire);
        biExResponse.item = [];
        Fhir.Questionnaire.EnsureStructuredResponse(biExquestionnaire, biExResponse);

        let formSum = 0; // summed up value stored to EBI_07 at the end

        const E2_I_082 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_082'); // Alltagskomp. Values: 4:E3_I_0390, 3:E3_I_0389, 2:E3_I_0388, 1:E3_I_0387
        //#region ... for "Verstehen" (EBI_01) ...
        let ebi01: number = NaN;
        switch (E2_I_082) {
            case 'E3_I_0390':
                ebi01 = 15;
                break;
            case 'E3_I_0389':
                ebi01 = 10;
                break;
            case 'E3_I_0388':
                ebi01 = 5;
                break;
            case 'E3_I_0387':
                ebi01 = 0;
                break;
        }

        if (!isNaN(ebi01)) {
            formSum += ebi01;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_01', 'EBI_01_' + this.makeTwo(ebi01), String(ebi01));
        }
        //#endregion

        //#region ... for "Sich verständlich machen" (EBI_02) ...
        let ebi02: number = NaN;
        const E2_I_063 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_063'); // Sich mitteilen, Values: 4:E3_I_0274, 2:E3_I_0273, 1:E3_I_0272
        if ((E2_I_063 === 'E3_I_0274') && (E2_I_082 === 'E3_I_0390' || E2_I_082 === 'E3_I_0389'))
            ebi02 = 15;
        else if ((E2_I_063 === 'E3_I_0274' && (E2_I_082 === 'E3_I_0388' || E2_I_082 === 'E3_I_0387'))
            || E2_I_063 === 'E3_I_0273')
            ebi02 = 5;
        else if (E2_I_063 === 'E3_I_0272')
            ebi02 = 0;

        if (!isNaN(ebi02)) {
            formSum += ebi02;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_02', 'EBI_02_' + this.makeTwo(ebi02));
        }
        //#endregion

        //#region ... for "Soziale Interaktionen" (EBI_03) ...
        // E2_I_065: Merkmale herausfordernden Verhaltens, Values: 4:E3_I_0263, 11:E3_I_0260, 12:E3_I_0261, 14:E3_I_0243, 13:E3_I_0262
        const E2_I_065 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_065');

        // E2_I_033: Orientierung (Person, Ort, Zeit, Situation), values: 4:E3_I_0129, 3:E3_I_0128, 2:E3_I_0127, 1:E3_I_0126
        const E2_I_033 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_033');

        let ebi03: number = NaN;
        if (E2_I_033 === 'E3_I_0129' || (E2_I_033 === 'E3_I_0128' && E2_I_065 === 'E3_I_0263'))
            ebi03 = 15;
        else if ((E2_I_033 === 'E3_I_0128' && (E2_I_065 === 'E3_I_0260' || E2_I_065 === 'E3_I_0261' || E2_I_065 === 'E3_I_0243' || E2_I_065 === 'E3_I_0262'))
            || (E2_I_033 === 'E3_I_0127'))
            ebi03 = 5;
        else if (E2_I_033 === 'E3_I_0126')
            ebi03 = 0;

        if (!isNaN(ebi03)) {
            formSum += ebi03;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_03', 'EBI_03_' + this.makeTwo(ebi03), String(ebi03));
        }
        //#endregion

        //#region ... for "Lösen von Alltagsproblemen" (EBI_04) ...
        let ebi04: number = NaN;
        const E2_I_069 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_069');
        switch (E2_I_069) {
            case 'E3_I_0283':
                ebi04 = 15;
                break;
            case 'E3_I_0256':
                ebi04 = 5;
                break;
            case 'E3_I_0255':
            case 'E3_I_0254':
                ebi04 = 0;
                break;
        }

        if (!isNaN(ebi04)) {
            formSum += ebi04;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_04', 'EBI_04_' + this.makeTwo(ebi04), String(ebi04));
        }
        //#endregion

        //#region ... for "Gedächtnis, Lernen und Orientierung" (EBI_05) ...
        let ebi05: number = NaN;
        switch (E2_I_082) {
            case 'E3_I_0390':
                ebi05 = 15;
                break;
            case 'E3_I_0389':
                ebi05 = 10;
                break;
            case 'E3_I_0388':
                ebi05 = 5;
                break;
            case 'E3_I_0387':
                ebi05 = 0;
                break;
        }

        if (!isNaN(ebi05)) {
            formSum += ebi05;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_05', 'EBI_05_' + this.makeTwo(ebi05), String(ebi05));
        }
        //#endregion

        //#region ... for "Sehen und Neglect" (EBI_06) ...
        let ebi06: number = NaN;
        const E2_I_062 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_062');
        switch (E2_I_062) {
            case 'E3_I_0270':
                ebi06 = 15;
                break;
            case 'E3_I_0269':
                ebi06 = 10;
                break;
            case 'E3_I_0268':
                ebi06 = 0;
                break;
        }

        if (!isNaN(ebi06)) {
            formSum += ebi06;
            QuestionnaireResponse.SetResponseItemCodingByLinkId(biExResponse, 'EBI_06', 'EBI_06_' + this.makeTwo(ebi06), String(ebi06));
        }
        //#endregion

        //#endregion

        //#region ... and store the result from formSum into "Summe" (EBI_07)
        QuestionnaireResponse.SetResponseItemIntByLinkId(biExResponse, 'EBI_07', formSum);
        //#endregion

        return formSum;
    }

    /************* PSYC SPECIFICS ***************/
    /**
     * Abklärung Gewaltrisiko
     */
    public calculateViolenceRisk(patient: PatientItem, assessment: any) {
        let sum = 0;
        /* Im epaPSYC werden mögliche (Indikatoren für ein vorliegendes Gewaltpotential des Patienten erfasst.
            Dies ermöglicht das Aufzeigen eines möglichen Bedarfes an vertiefender Diagnostik und damit einhergehend die Möglichkeit für eine Früherkennung.
            Es wird ein Abklärungserfordernis ausgewiesen, sobald mindestens ZWEI der aufgeführten Triggeritems
            in der Tabelle mit den relevanten Werten beurteilt werden */


        // E2_I_174, Antrieb
        const E2_I_174 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_044');
        if (E2_I_174 === 'E3_I_0710') sum += 1;  // 1b

        // E2_I_194, Fähigkeit mit Gefühlen umzugehen
        const E2_I_194 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_194');
        if (['E3_I_0785', 'E3_I_0786', 'E3_I_0787'].indexOf(E2_I_194) > -1) sum += 1;

        // E2_I_033 : "Orientierung (Person, Ort, Zeit, Situation)"
        const E2_I_033 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_033');
        if (['E3_I_0128', 'E3_I_0127', 'E3_I_0126'].indexOf(E2_I_033) > -1) sum += 1;

        // E2_I_200 : "Sinnestäuschungen"
        const E2_I_200 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_200');
        //   E3_I_0803 : [option] : "Ja"
        if (E2_I_200 === 'E3_I_0803') sum += 1;

        const E2_I_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_201');
        if (['E3_I_0807', 'E3_I_0808', 'E3_I_0809'].indexOf(E2_I_201)) sum += 1;

        // E2_I_210 : [choice] : "Verhalten, fremdaggressiv"
        const E2_I_210 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_210');
        if (['E3_I_0835', 'E3_I_0836'].indexOf(E2_I_210) > -1) sum += 1;

        this.violence = {
            isRisk: sum >= 2,
            riskSum: sum == 0 ? 4 : 1
        };

        return this.violence;
    }

    public violence = {
        isRisk: false,
        riskSum: -1
    };

    /**
     * Abklärung Suizidalität
     */
    public clarificationSuicidality(patient: PatientItem, assessment: any) {
        /*
        Im epaPSYC werden mögliche (Indikatoren für ein vorliegendes Suizid-Risiko erfasst. Dies ermöglicht das Aufzeigen eines möglichen Bedarfes
        an vertiefender Diagnostik und damit einhergehend die Möglichkeit für eine Früherkennung.
        Neu ab epaPSYC 1.1.00 ist, dass einige Risikoindikatoren auf Grund ihrer hohen Bedeutung eine höhere „Gewichtung“ erhalten.
        Damit wird zukünftig das Abklärungserfordernis mit einem Summenscore erfasst.

        Es wird ein Abklärungserfordernis ausgewiesen, sobald der Summenscore 3 Punkte oder mehr beträgt.
        */
        let sum = 0;

        const anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);

        const E0_I_001 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E0_I_001');
        if (E0_I_001 === 'E0_I_0238') sum += 1;

        const E0_I_0198 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'E0_I_0198');
        if (E0_I_0198 === "E0_I_0200") sum += 1;

        const E0_I_0255 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'E0_I_0255');
        if (E0_I_0255 === 'E0_I_0259') sum += 1;

        const E0_I_0222 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'E0_I_0222'); // Vorgeschichte suizidalen Verhaltens
        if (E0_I_0222 === 'E0_I_0224' || E0_I_0222 === 'E0_I_0225') sum += 3;

        const E0_I_0227 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'E0_I_0227'); // Aktueller Substanzgebrauch
        if (E0_I_0227 === 'E0_I_0229') sum += 1;

        const E2_I_084 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_084'); // Fähigkeit neue Beziehungen einzugehen
        if (E2_I_084 === 'E3_I_0396' || E2_I_084 === 'E3_I_0395') sum += 1;

        const E2_I_188 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_188'); // Gefühl der Hoffnung [D]
        if (E2_I_188 === 'E3_I_0764' || E2_I_188 === 'E3_I_0765') sum += 3;

        const E2_I_193 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_193'); // Fähigkeit mit schwierigen Situationen umzug.
        if (E2_I_193 === 'E3_I_0781' || E2_I_193 === 'E3_I_0782') sum += 1;

        const E2_I_194 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_194'); // Fähigkeit mit Gefühlen umzugehen
        if (E2_I_194 === 'E3_I_0786' || E2_I_194 === 'E3_I_0787') sum += 1;

        const E2_I_200 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_200'); // Sinnestäuschungen
        if (E2_I_200 === 'E3_I_0803') sum += 1;

        const E2_I_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_201'); // Wahnerleben
        if (E2_I_201 === 'E3_I_0808' || E2_I_201 === 'E3_I_0809') sum += 1;

        const E2_I_212 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_212'); // Suizidgedanken [D]
        if (['E3_I_0843', 'E3_I_0844', 'E3_I_0845'].indexOf(E2_I_212) > -1) sum += 1;

        const E2_I_213 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_213'); // Verhalten, suizidal
        if (['E3_I_0848', 'E3_I_0849', 'E3_I_0850'].indexOf(E2_I_213) > -1) sum += 3;

        this.suicidality = {
            isRisk: sum >= 3,
            riskSum: sum
        };
    }

    suicidality = {
        isRisk: false,
        riskSum: -1
    };

    /**
     * SFI - Anzeige als Zahl
     */
    public calculateSFI(patient: PatientItem, assessment: any) {
        let sum = 0;

        //#region E2_I_177, Alltagsplanung umsetzen
        const E2_I_177 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_177');
        switch (E2_I_177) {
            case 'E3_I_0719':
                sum += 4;
                break;
            case 'E3_I_0720':
                sum += 3;
                break;
            case 'E3_I_0721':
                sum += 2;
                break;
            case 'E3_I_0722':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_180, Körperpflege
        const E2_I_180 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_180');
        switch (E2_I_180) {
            case 'E3_I_0733':
                sum += 4;
                break;
            case 'E3_I_0734':
                sum += 3;
                break;
            case 'E3_I_0735':
                sum += 2;
                break;
            case 'E3_I_0736':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_181 : [choice] : "Kleiden"
        const E2_I_181 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_181');
        switch (E2_I_181) {
            case 'E3_I_0737':
                sum += 4;
                break;
            case 'E3_I_0738':
                sum += 3;
                break;
            case 'E3_I_0739':
                sum += 2;
                break;
            case 'E3_I_0740':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_084 : [choice] : "Fähigkeit neue Beziehungen einzugehen"
        const E2_I_084 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_084');
        switch (E2_I_084) {
            case 'E3_I_0398':
                sum += 4;
                break;
            case 'E3_I_0397':
                sum += 3;
                break;
            case 'E3_I_0396':
                sum += 2;
                break;
            case 'E3_I_0395':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_188 : [choice] : "Gefühl der Hoffnung [D]"
        const E2_I_188 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_188');
        switch (E2_I_188) {
            case 'E3_I_0762':
                sum += 4;
                break;
            case 'E3_I_0763':
                sum += 3;
                break;
            case 'E3_I_0764':
                sum += 2;
                break;
            case 'E3_I_0765':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_190 : [choice] : "Selbstmitgefühl"
        const E2_I_190 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_190');
        switch (E2_I_190) {
            case 'E3_I_0769':
                sum += 4;
                break;
            case 'E3_I_0770':
                sum += 3;
                break;
            case 'E3_I_0771':
                sum += 2;
                break;
            case 'E3_I_0772':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_194 : [choice] : "Fähigkeit mit Gefühlen umzugehen"
        const E2_I_194 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_194');
        switch (E2_I_194) {
            case 'E3_I_0784':
                sum += 4;
                break;
            case 'E3_I_0785':
                sum += 3;
                break;
            case 'E3_I_0786':
                sum += 2;
                break;
            case 'E3_I_0787':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_013 : [choice] : "Essen"
        const E2_I_013 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_013');
        switch (E2_I_013) {
            case 'E3_I_0029':
                sum += 4;
                break;
            case 'E3_I_0028':
                sum += 3;
                break;
            case 'E3_I_0027':
                sum += 2;
                break;
            case 'E3_I_0026':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_211 : [choice] : "Verhalten, selbstverletzend"
        const E2_I_211 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_211');
        switch (E2_I_211) {
            case 'E3_I_0837':
                sum += 4;
                break;
            case 'E3_I_0838':
                sum += 3;
                break;
            case 'E3_I_0839':
                sum += 2;
                break;
            case 'E3_I_0840':
                sum += 1;
                break;
        }
        //#endregion

        //#region E2_I_213 : [choice] : "Verhalten, suizidal"
        const E2_I_213 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_213');
        switch (E2_I_213) {
            case 'E3_I_0847':
                sum += 4;
                break;
            case 'E3_I_0848':
                sum += 3;
                break;
            case 'E3_I_0849':
                sum += 2;
                break;
            case 'E3_I_0850':
                sum += 1;
                break;
        }
        //#endregion

        if (sum > 40) sum = 40;
        else if (sum < 10) sum = 10;

        this.SFI = sum;

        return sum;
    }

    public SFI: number = NaN;

    public async calculateSpi(patient: PatientItem, assessment?: any): Promise<IRiskItem> {
        return new Promise((resolve, reject) => {
            try {
                const ri = this.calculateSpiSync(patient, assessment);
                resolve(ri);
            } catch (e) {
                reject(e.message || e);
            }
        });
    }

    public calculateSpiSync(patient: PatientItem, assessment?: any): IRiskItem {
        if (!assessment) assessment = patient.latestAssessment;
        let sum = 0;

        //#region Fortbewegung, E2_I_001
        let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001');
        switch (value) {
            case 'E3_I_0004':
                sum += 4;
                break;
            case 'E3_I_0003':
                sum += 3;
                break;
            case 'E3_I_0002':
                sum += 2;
                break;
            case 'E3_I_0001':
                sum += 1;
                break;
        }
        //#endregion

        //#region körperpflege OK, E2_I_009
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_009');
        switch (value) {
            case 'E3_I_0105':
                sum += 4;
                break;
            case 'E3_I_0104':
                sum += 3;
                break;
            case 'E3_I_0103':
                sum += 2;
                break;
            case 'E3_I_0102':
                sum += 1;
                break;
        }
        //#endregion

        //#region körperpflege UK, E2_I_010
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_010');
        switch (value) {
            case 'E3_I_0110':
                sum += 4;
                break;
            case 'E3_I_0109':
                sum += 3;
                break;
            case 'E3_I_0108':
                sum += 2;
                break;
            case 'E3_I_0107':
                sum += 1;
                break;
        }
        //#endregion

        //#region An- Auskleiden OK, E2_I_011
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_011');
        switch (value) {
            case 'E3_I_0115':
                sum += 4;
                break;
            case 'E3_I_0114':
                sum += 3;
                break;
            case 'E3_I_0113':
                sum += 2;
                break;
            case 'E3_I_0112':
                sum += 1;
                break;
        }
        //#endregion

        //#region An- Auskleiden UK, E2_I_012
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_012');
        switch (value) {
            case 'E3_I_0120':
                sum += 4;
                break;
            case 'E3_I_0119':
                sum += 3;
                break;
            case 'E3_I_0118':
                sum += 2;
                break;
            case 'E3_I_0117':
                sum += 1;
                break;
        }
        //#endregion

        //#region Essen, E2_I_013
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_013');
        switch (value) {
            case 'E3_I_0029':
                sum += 4;
                break;
            case 'E3_I_0028':
                sum += 3;
                break;
            case 'E3_I_0027':
                sum += 2;
                break;
            case 'E3_I_0026':
                sum += 1;
                break;
        }
        //#endregion

        //#region Trinken, E2_I_017
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_017');
        switch (value) {
            case 'E3_I_0047':
                sum += 4;
                break;
            case 'E3_I_0046':
                sum += 3;
                break;
            case 'E3_I_0045':
                sum += 2;
                break;
            case 'E3_I_0044':
                sum += 1;
                break;
        }
        //#endregion

        //#region Urinausscheidung durchführen, E2_I_024
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_024');
        switch (value) {
            case 'E3_I_0071':
                sum += 4;
                break;
            case 'E3_I_0070':
                sum += 3;
                break;
            case 'E3_I_0069':
                sum += 2;
                break;
            case 'E3_I_0068':
                sum += 1;
                break;
        }
        //#endregion

        //#region Stuhlausscheidung durchführen, E2_I_026
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_026');
        switch (value) {
            case 'E3_I_0081':
                sum += 4;
                break;
            case 'E3_I_0080':
                sum += 3;
                break;
            case 'E3_I_0079':
                sum += 2;
                break;
            case 'E3_I_0078':
                sum += 4;
                break;
        }
        //#endregion

        //#region Informationen verarbeiten/verstehen, E2_I_082
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_082');
        switch (value) {
            case 'E3_I_0390':
                sum += 4;
                break;
            case 'E3_I_0389':
                sum += 3;
                break;
            case 'E3_I_0388':
                sum += 2;
                break;
            case 'E3_I_0387':
                sum += 1;
                break;
        }
        //#endregion

        //#region Auswertung:
        let riskItem: IRiskItem = {
            name: "SPI",
            linkIdSum: "risk_spi_sum",
            linkIdIsRisk: "risk_spi",
            hasError: false,
            error: undefined,
            sum: sum,
            textSum: '',
            textRisk: '',
            buttonText: this.i18n.tr("not_aviable"),
            title: this.i18n.tr("riskofpkms"),
            showButton: false,
            careLevel: undefined,
            versorgung: false,
            isRisk: false
        };

        this.SPI = sum;

        riskItem.isRisk = riskItem.sum <= AnalyzeService.PkmsRelevanceStart;
        riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
        riskItem.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);
        let str = this.i18n.tr("analyse_info_text");
        str = str.replace("%PATIENT%", patient.display)
            .replace("%CARELEVEL%", riskItem.textSum)
            .replace("%SPI%", riskItem.sum.toString());

        riskItem.textRisk = str;

        if (riskItem.isRisk) {
            riskItem.buttonText = this.i18n.tr("inform_social_services");
            riskItem.showButton = true;
        } else {
            riskItem.textRisk = this.i18n.tr("noobservablerisk");
        }

        riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
        riskItem.versorgung = riskItem.sum <= 32;

        // update patient - because we can
        patient.pkms_relevant = riskItem.sum <= AnalyzeService.PkmsRelevanceStart;
        patient.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);
        patient.careLevelString = Fhir.Tools.SpiToString(riskItem.sum);
        //#endregion

        return riskItem;
    }

    public SPI: number = NaN;


    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        let qList = await QuestionnaireService.GetQuestionnaireIds();
        await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);

        let aConfig = ConfigService.GetFormSettings('assessment');
        if (aConfig) {
            let aQ = QuestionnaireService.GetQuestionnaireByNameDirect(aConfig.questionnaireName);
            if (aQ) {
                if (!assessment || (assessment && assessment.questionnaire && assessment.questionnaire.reference.indexOf('/' + aQ.id) === -1) || this.assessmentName !== aQ.name) {
                    if (aConfig && aQ) {
                        assessment = QuestionnaireService.GetLatestResponseOfType(patient, aQ.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                        if (assessment) {
                            QuestionnaireService.__listResult.QAssessmentId = aQ.id;
                            qList.QAssessmentId = aQ.id;
                            this.assessmentName = aQ.name;
                        }
                    }
                }
            }
        }

        if (ConfigService.Debug) 
            console.debug('This is Analyzer Version: ' + this._version + ' using AssessmentName: ' + this.assessmentName);

        if (!assessment) {
            if (ConfigService.Debug) console.warn("No Assessment in ", patient);
            return;
        }

        let raBackup = NitTools.Clone(patient.currentRisks);
        // check for the riskAssessment
        if (updateRiskAssessment && !patient.currentRisks) {
            let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, anamnesis);
        }

        this.clarificationSuicidality(patient, assessment);
        this.calculateSFI(patient, assessment);
        this.calculateViolenceRisk(patient, assessment);
        this.calculateNCD(patient, assessment);
        this.calculateExpertReviewRiskDecu(patient, assessment);

        let resultPKMS = this.calculateSpiSync(patient, assessment);
        let resultDecu = this.calculateRiskDecu(patient, assessment);
        let resultPneumo = this.calculatePneumo(patient, assessment);
        let resultFall = this.calculateFall(patient, assessment);
        let resultVDD = this.calculateVdd(patient, assessment);
        let resultKontinenz = this.calculateIncontinenceProfile(patient, assessment);
        let resultNRS = this.calculateNRS(patient, assessment, qList, resultPKMS.sum);

        let bi = this.calculateBarthelIndex(patient, assessment, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndex"));
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        let biEx = this.calculateBartheldIndexEx(patient, assessment, anamnesis, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndexEx"));
        let icdItems = await this.icdService.getICDCodes(patient);
        let showMissingFieldsWarning = false;
        icdItems = icdItems.filter(o => o.test());
        /*        if (ConfigService.Debug) {
                    console.info("TODO:");
                    console.info("[Fhir.Analyse] - ICDS:", icdItems);
                } */

        //#region set pkms value
        // PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment);
        await PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment, patient.careLevelText, patient.careLevelColor, resultPKMS.sum);

        let itemPkmsText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_sum_text', true);
        if (itemPkmsText) itemPkmsText.answer = [{valueString: resultPKMS.textSum}];
        else {
            console.warn("No 'risk_spi_sum_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsRText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_risk_text', true);
        if (itemPkmsRText) itemPkmsRText.answer = [{valueString: resultPKMS.textRisk}];
        else {
            console.warn("No 'risk_spi_risk_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdSum, true);
        if (itemPkmsSum) {
            itemPkmsSum.answer = [
                {valueInteger: resultPKMS.sum}
            ];
            itemPkmsSum.linkId = resultPKMS.linkIdSum;
        } else {
            console.warn(`No '${resultPKMS.linkIdSum}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPkmsRisk = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdIsRisk, true);
        if (itemPkmsRisk) {
            itemPkmsRisk.answer = [
                {valueBoolean: resultPKMS.isRisk}
            ];
            itemPkmsRisk.linkId = resultPKMS.linkIdIsRisk;
        } else {
            console.warn(`No '${resultPKMS.linkIdIsRisk}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        //#endregion

        //#region Decubitus
        // WO HIN SPEICHERN?
        let itemDecu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field, true);
        if (itemDecu) {
            itemDecu.answer = [
                {valueBoolean: resultDecu.sum >= 1}
            ];
        } else {
            console.warn(`No '${resultDecu.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemDecuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_sum", true);
        if (itemDecuSum) {
            itemDecuSum.answer = [
                {valueInteger: resultDecu.sum}
            ];
        } else {
            console.warn(`No '${resultDecu.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let decuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_text", true);
        if (decuText) {
            decuText.answer = [{valueString: resultDecu.text}];
        } else {
            console.warn(`No '${resultDecu.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region pneumo
        let itemPneu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field, true);
        if (itemPneu) {
            itemPneu.answer = [
                {valueBoolean: resultPneumo.isRisk}
            ];
            itemPneu.linkId = resultPneumo.field;
        } else {
            console.warn(`No '${resultPneumo.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + '_sum', true);
        if (itemPneuSum) {
            itemPneuSum.answer = [
                {valueInteger: resultPneumo.sum}
            ];
        } else {
            console.warn(`No '${resultPneumo.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + "_text", true);
        if (itemPneuText) {
            itemPneuText.answer = [{valueString: resultPneumo.text}];
            itemPneuSum.linkId = resultPneumo.field + '_sum';
        } else {
            console.warn(`No '${resultPneumo.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region fall
        let itemFall = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field, true);
        if (itemFall) {
            itemFall.answer = [
                {
                    valueBoolean: resultFall.isRisk
                }
            ];
            itemFall.linkId = resultFall.field;
        } else {
            console.warn(`No '${resultFall.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemFallText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field + "_text", true);
        if (itemFallText) {
            itemFallText.answer = [{
                valueString: resultFall.text
            }];
        } else {
            console.warn(`No '${resultFall.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region vdd
        let itemVdd = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field, true);
        if (itemVdd) {
            itemVdd.answer = [
                {
                    valueBoolean: resultVDD.isRisk
                }
            ];
            itemVdd.linkId = resultVDD.field;
        } else {
            console.warn(`No '${resultVDD.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemVddText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field + "_text", true);
        if (itemVddText) {
            itemVddText.answer = [{valueString: resultVDD.text}];
        } else {
            console.warn(`No '${resultVDD.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region continence
        let itemKonti = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field, true);
        if (itemKonti) {
            itemKonti.answer = [{valueInteger: resultKontinenz.value}];
        } else {
            console.warn(`No '${resultKontinenz.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemKontiText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field + "_text", true);
        if (itemKontiText) {
            itemKontiText.answer = [{valueString: resultKontinenz.text}];
        } else {
            console.warn(`No '${resultKontinenz.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        // TODO: DOUBLE CHECK the assigned values an especially the storage of the NRS items
        //#region NRS
        if (resultNRS) {
            let nrsSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdSum, true);
            if (nrsSumItem) {
                nrsSumItem.answer = [
                    {
                        valueInteger: resultNRS.riskSum
                    }
                ];
                nrsSumItem.linkId = resultNRS.linkIdSum;
            } else {
                console.warn(`No '${resultNRS.linkIdSum}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdIsRisk, true);
            if (nrsRiskItem) {
                nrsRiskItem.answer = [
                    {valueInteger: resultNRS.errorResult}
                ];
                nrsRiskItem.linkId = resultNRS.linkIdIsRisk;
            } else {
                console.warn(`No '${resultNRS.linkIdIsRisk}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItemText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_nrs_warning', true);
            if (nrsRiskItemText) {
                nrsRiskItemText.answer = [{valueString: resultNRS.riskText}];
            } else {
                console.warn(`No 'risk_nrs_warning' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }
        } else {
            console.warn('No NRS-Result generated');
        }
        //#endregion

        //#region BI
        let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BI_11', true);
        if (biItem) {
            biItem.answer = [
                {valueInteger: bi}
            ];
        } else {
            console.warn(`No 'BI_11' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region BI-EX
        let biExItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'EBI_07', true);
        if (biExItem) {
            biExItem.answer = [
                {valueInteger: biEx}
            ];
        } else {
            console.warn(`No 'EBI_07' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region if this is the latestAssessment update everything and even riskassessment
        if (patient.latestAssessment && patient.latestAssessment.id === assessment.id) {
            let spiSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdSum, true);
            if (spiSumItem) spiSumItem.answer = [{valueInteger: resultPKMS.sum}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(spiSumItem, resultPKMS.sum, resultPKMS.textSum);

            let spiRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdIsRisk, true);
            Fhir.QuestionnaireResponse.SetResponseItemCoding(spiRiskItem, String(resultPKMS.isRisk), resultPKMS.textRisk);
            await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

            //#region update RiskAssessment
            if (updateRiskAssessment) {
                CIRiskAssessment.ApplyRisksFromAssessment(patient.currentRisks, assessment);

                this.updateRiskAssessmentPrediction(resultPKMS, patient)
                    .updateRiskAssessmentPrediction(
                        {
                            linkIdSum: resultDecu.field,
                            sum: resultDecu.sum,
                            textSum: resultDecu.text
                        }, patient)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultPneumo, patient, true)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultFall, patient, false)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultVDD, patient, false);
                if (resultKontinenz) {
                    this.updateRiskAssessmentPrediction({
                        linkIdSum: resultKontinenz.field,
                        sum: resultKontinenz.value,
                        textSum: String(resultKontinenz.text)
                    }, patient);
                }

                if (resultNRS) {
                    this.updateRiskAssessmentPrediction(
                        {
                            linkIdIsRisk: resultNRS.linkIdIsRisk,
                            isRisk: resultNRS.riskSum > 0,
                            riskText: resultNRS.riskText,
                            linkIdSum: resultNRS.linkIdSum,
                            sum: resultNRS.errorResult,
                            textSum: resultNRS.riskText
                        }, patient);
                }

                this.updateRiskAssessmentPrediction({
                    linkIdSum: "BarthelIndex",
                    sum: String(bi),
                    textSum: String(bi)
                }, patient)
                    .updateRiskAssessmentPrediction({
                        linkIdSum: "BarthelIndexEx",
                        sum: String(biEx),
                        textSum: String(biEx)
                    }, patient);

                if (showMissingFieldsWarning) {
                    let msg = "There are missing fields in the Assessment.<br />Please read the Console Warnings and update the Questionnaire.";
                    // RuntimeInfo.ShowInfo(msg);
                    console.warn(msg);
                }

                if (anamnesis && assessment) {
                    if ((["finished", "ameneded"].indexOf(anamnesis.status) > -1) && (["finished", "ameneded"].indexOf(assessment.status) > -1)) {
                        patient.currentRisks.status = "final";
                    }
                }

                await this.updateRiskAssessmentValues(patient, assessment, patient.currentRisks);
                if (!CIRiskAssessment.AreEqual(patient.currentRisks, raBackup) && storeRiskAssessment) {
                    if (ConfigService.Debug) console.debug("UPDATE RISKASSESSMENT\n------WRITING RA ---------------", patient.currentRisks);

                    patient.currentRisks = <any>await this.fhirService.update(patient.currentRisks);
                }
            }
            //#endregion
        }
        //#endregion

        await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

        this.patientChangeNotifier.notify(patient);

        return {
            pkmsIsRisk: resultPKMS.isRisk,
            pkmsSum: resultPKMS.sum
        };
    }
}

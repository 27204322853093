import {IRiskItem} from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, Questionnaire, QuestionnaireResponse} from "resources/classes/FhirModules/Fhir";
import {AnalyzerClass} from '../AnalyzerClass';
import {NitTools} from 'resources/classes/NursitTools';
import {QuestionnaireService} from '../QuestionnaireService';
import {fhirEnums} from "../../classes/fhir-enums";
import {ConfigService} from "../ConfigService";
import {Epa2_3Nrs, INrsResult} from "./epa2_3_Subs/epa2_3-nrs";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {PatientItem} from "../../classes/Patient/PatientItem";

export class AnalyzerLTC1201 extends AnalyzerClass {
    _version = 'epaLTC_1.2.01';
    description: string = "This Analyzer version EPA 2.2 for LTC";
    needToStoreFlags: boolean = true;
    //region vars
    private biResponse;
    private biQuestionnaire;
    private biExQuestionnaire;
    private biExResponse;
    questionnaireAssessment: any;
    questionnaireAnamnesis: any;
    //endregion

    //region Helper-Methods used in analysis
    private storeOption(questionnaire: any, target: any, linkId: string, value: number) {
        let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
        let _code = linkId + '_' + NitTools.ToString(value, 2, '0');
        let _display = String(value);

        // try to find the correct option (because we should not trust the casings)
        if (questionnaireItem.option) {
            let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code && o.valueCoding.code.endsWith('_' + String(value)));
            if (option) {
                _code = option.valueCoding.code;
                if (option.valueCoding.display) _display = option.valueCoding.display;
            }
        }

        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(target, linkId, true);
        item.answer = [
            {
                valueCoding: {
                    code: _code,
                    display: _display
                }
            }
        ];
    }

    private storeToBi(linkId: string, value: number) {
        this.storeOption(this.biQuestionnaire, this.biResponse, linkId, value);
    }

    private storeToBiEx(linkId: string, value: number) {
        this.storeOption(this.biExQuestionnaire, this.biExResponse, linkId, value);
    }

    private static logValueMappingFailure(obj: any, id: string): string {
        let msg = `Unmapped value found in ${id}: ${obj[id]}`;
        console.warn(msg);

        return msg;
    }

    private __getOptionOrdinalValue(questionnaire, response, answerLinkId) {
        let textVal = QuestionnaireResponse.GetResponseItemValueByLinkId(response, answerLinkId);
        let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, answerLinkId);
        if (questionnaireItem && questionnaireItem.option) {
            let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code && o.valueCoding.code.toUpperCase() === String(textVal).toUpperCase());
            if (option) {
                let ordValueExtension = Fhir.Tools.GetOrCreateExtension(option, '/questionnaire-ordinalValue', false);
                if (ordValueExtension) {
                    let sOrdVal = String(Fhir.Tools.GetExtensionValue(ordValueExtension)).trim();
                    if (sOrdVal) {
                        return parseInt(sOrdVal);
                    } else {
                        console.warn('No ordinal value specified for option: ', ordValueExtension);
                    }
                } else {
                    console.warn('No questionnaire-ordinalValue extension found in questionnaireItem option: ', option);
                }
            } else {
                console.warn('Option for value "' + answerLinkId + '" not found in : ', questionnaireItem);
            }
        } else {
            if (!questionnaireItem)
                console.warn('No Questionnaire-Item with linkId "' + answerLinkId + '" found in questionnaire:', questionnaire);
            else if (!questionnaireItem.option)
                console.warn('No option property found for questionnaire-item with linkId "' + answerLinkId + '":', questionnaireItem);
        }

        return NaN;
    }

    private getAssessmentOptionOrdinalValue(response, linkId) {
        return this.__getOptionOrdinalValue(this.questionnaireAssessment, response, linkId);
    }

    private getAnamnesisOptionOrdinalValue(response, linkId) {
        return this.__getOptionOrdinalValue(this.questionnaireAnamnesis, response, linkId);
    }

    private async ensureQuestionnaires(patient: PatientItem) {
        if (this.questionnaireAssessment && this.questionnaireAnamnesis) return;

        await ConfigService.LoadConfigOverride(patient.ward);
        let cfgAssessment = ConfigService.GetFormSettings('assessment');
        let cfgAnamnesis = ConfigService.GetFormSettings('anamnesis');

        if (cfgAssessment && cfgAssessment.questionnaireName) {
            this.questionnaireAssessment = await QuestionnaireService.GetQuestionnaireByName(cfgAssessment.questionnaireName);
        }
        if (cfgAnamnesis && cfgAnamnesis.questionnaireName) {
            this.questionnaireAnamnesis = await QuestionnaireService.GetQuestionnaireByName(cfgAnamnesis.questionnaireName);
        }
    }

    //endregion

    //region Converted to new Version:
    /**
     * Calculate the Self Care Index (Selbst Pflege Index)
     * @param patient the patient to calc for
     * @param from the assessment to use for calculation
     */
    public async calculateSpi(patient: PatientItem, from?: any): Promise<IRiskItem> {
        /** -= IMPORTANT  =-
         * we don't update the Questionnaire values in here,
         * because it is constantly called from assessment-view,
         * which handles the Updates */
        await this.ensureQuestionnaires(patient);
        patient.careLevel = undefined;
        patient.careLevelString = undefined;
        if (!patient.questionnaireResponses) patient.questionnaireResponses = [];

        let riskItem: IRiskItem = {
            name: "SPI",
            linkIdSum: "risk_spi_sum",
            linkIdIsRisk: "risk_spi",
            hasError: false,
            error: undefined,
            sum: undefined,
            textSum: '',
            textRisk: '',
            buttonText: this.i18n.tr("not_aviable"),
            title: this.i18n.tr("riskofpkms"),
            showButton: false,
            careLevel: undefined,
            versorgung: false
        };
        let assessment: any = from || patient.latestAssessment;
        if (!assessment) return riskItem;
        let cfg = ConfigService.GetFormSettings('analysis');
        let spi_risk_start = 32;
        if (cfg && cfg["analyse"] && cfg["analyse"].spi_risk_start)
            spi_risk_start = cfg["analyse"].spi_risk_start;

        try {
            let messages = [];
            let fieldNames = ['E2_I_001', 'E2_I_009', 'E2_I_010', 'E2_I_011', 'E2_I_012', 'E2_I_013', 'E2_I_017', 'E2_I_024', 'E2_I_026', 'E2_I_082'];
            let fields = {};
            let sum = 0;
            fieldNames.forEach(id => {
                let ordValue = this.getAssessmentOptionOrdinalValue(assessment, id);
                if (isNaN(ordValue)) messages.push(AnalyzerLTC1201.logValueMappingFailure(fields, id));
                else sum += ordValue;
            });

            let valid = messages.length === 0;

            riskItem.sum = valid ? sum : NaN;
            riskItem.isRisk = valid ? riskItem.sum <= spi_risk_start : false;

            riskItem.textSum = valid ? Fhir.Tools.SpiToString(riskItem.sum) : messages.join(',\n');
            riskItem.careLevel = valid ? Fhir.Tools.SpiToCareLevel(riskItem.sum) : NaN;

            let str = this.i18n.tr("analyse_info_text");
            str = str.replace("%PATIENT%", patient.display)
                .replace("%CARELEVEL%", riskItem.textSum)
                .replace("%SPI%", riskItem.sum.toString());

            riskItem.textRisk = valid ? str : messages.join(',\n');

            if (riskItem.isRisk) {
                riskItem.buttonText = this.i18n.tr("inform_social_services");
                riskItem.showButton = true;
            } else {
                riskItem.textRisk = this.i18n.tr("noobservablerisk");
            }

            riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
            riskItem.versorgung = riskItem.sum <= 32;
        } catch (e) {
            riskItem.hasError = true;
            riskItem.error = e.message;
        }

        // patient.pkms_relevant = riskItem.sum <= AnalyzeService.PkmsRelevanceStart;
        patient.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);
        patient.careLevelString = Fhir.Tools.SpiToString(riskItem.sum);

        if (assessment) {
            const riskGroupItemSpiValue = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'E0_I_0048');
            const riskGroupItemSpiValid = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'E0_I_075');
            if (riskGroupItemSpiValue) {
                if (!isNaN(riskItem.sum)) {
                    riskGroupItemSpiValue.answer = [{valueInteger: riskItem.sum}];
                } else {
                    riskGroupItemSpiValue.answer = [];
                }
            }

            if (riskGroupItemSpiValid) {
                const valid = isNaN(riskItem.sum);
                let validGroupValue = valid ? 'E0_I_0049' : 'E0_I_075_04';
                let validGroupDisplay = valid ? 'SPI-Wert' : 'ungültig';

                riskGroupItemSpiValid.answer = [{valueCoding: {code: validGroupValue, display: validGroupDisplay}}];
            }
        }

        return riskItem;
    }

    /**
     * Calculate the Barthelindex for the patient
     * @param patient - the patient to calculate for
     * @param assessment - the assessment to take values from
     * @param biResponse - the BarthelIndex QuestionnaireResponse to store the calculated data to
     */
    public calculateBarthelIndex(patient: PatientItem, assessment: any, biResponse: any): number {
        if (ConfigService.Debug) console.debug('[calculateBarthelIndex] started');
        let resultSum = 0;
        this.biResponse = biResponse;
        this.biQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect('CareITBarthelIndex');

        try {
            //region do some checks first ...
            if (!biResponse) {
                let msg = "No BarthelIndex-Response given";
                console.warn(msg);
                return NaN;
            }

            if (biResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
                let msg = "BarthelIndex is not in state in-progress. Returning stored value";
                console.debug(msg);
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "BI_11");
                if (item) {
                    let bi11Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                    return parseInt(bi11Value);
                } else {
                    //msg = "No previous stored value found for BI_11 in response. Aborting";
                    return NaN;
                }
            }
            //endregion

            //region ensure a valid and structured BartelIndex Response with default values set
            Fhir.Questionnaire.EnsureStructuredResponse(this.biQuestionnaire, biResponse);
            Fhir.Questionnaire.SetDefaultValues(this.biQuestionnaire, biResponse, undefined);
            //endregion

            //region used Response-Values:
            let E2_I_001 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_001'); // Fortbewegung - war 01_01
            let E2_I_009 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_009'); // Körperpflege Oberkörper - war 05_01
            let E2_I_010 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_010'); // Körperpflege Unterkörper - war 05_02
            let E2_I_011 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_011'); // An-/Auskleiden Oberkörper - war 05_03
            let E2_I_012 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_012'); // An-/Auskleiden Unterkörper - war 05_04
            let E2_I_013 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "E2_I_013"); // Essen - war 03_01
            let E2_I_025 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_025'); // Urinausscheidung kontrollieren, war 04_02
            let E2_I_026 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_026'); // Stuhlausscheidung durchführen
            let E2_I_027 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_027'); // Stuhlausscheidung kontrollieren, war 04_04
            let E2_I_030 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_030'); // Stuhlableitungssystem, war 04_10
            let E2_I_067 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "E2_I_067"); // Sondenernährung, war 03_18
            let E2_I_167 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_167'); // Positionswechsel im Liegen, war ursprünglich 01_02 dann 01_16
            //endregion

            //region BI_01 - Essen +
            let bi_01_value = 0;
            if (E2_I_013 === 'E3_I_0029') {
                bi_01_value = 10;
            } else if (
                ((E2_I_013 === 'E3_I_0028' || E2_I_013 === 'E3_I_0027' || E2_I_013 === 'E3_I_0026') && E2_I_067 === 'E3_I_0248')
                || (E2_I_013 === 'E3_I_0028' && E2_I_067 === 'E3_I_0249')
            ) {
                bi_01_value = 5;
            } else if (
                (E2_I_013 === 'E3_I_0027' || E2_I_013 === 'E3_I_0026')
                && E2_I_067 === 'E3_I_0249'
            ) // already default, but to be complete like the PDF
            {
                bi_01_value = 0;
            }

            // add bi01 value to formsum
            resultSum += bi_01_value;

            // store bi 01
            this.storeToBi('BI_01', bi_01_value);
            //endregion

            //region BI_02 - Aufsetzen/Umsetzen +
            let bi_02_value = 0;
            let E2_I_001_int = this.getAssessmentOptionOrdinalValue(assessment, 'E2_I_001');

            /* switch (E2_I_001) {
                case 'E3_I_0001':
                    E2_I_001_int = 1;
                    break;
                case 'E3_I_0002':
                    E2_I_001_int = 2;
                    break;
                case 'E3_I_0003':
                    E2_I_001_int = 3;
                    break;
                case 'E3_I_0004':
                    E2_I_001_int = 4;
                    break;
            } */

            if (E2_I_167 === 'E3_I_0682') bi_02_value = 15;
            else if (E2_I_167 === 'E3_I_0683') bi_02_value = 10;
            else if (E2_I_167 === 'E3_I_0684' && E2_I_001_int > 1) bi_02_value = 5;
            else if ((E2_I_167 === 'E3_I_0685' && E2_I_001 === 'E3_I_0001') || (E2_I_167 === 'E3_I_0685')) bi_02_value = 0; // already default, but to be complete like the PDF

            resultSum += bi_02_value;
            this.storeToBi('BI_02', bi_02_value);
            //endregion

            //region BI_03 - Sich waschen +
            let bi_03_value = 0;

            if (E2_I_009 === 'E3_I_0105') bi_03_value = 5;
            resultSum += bi_03_value;
            this.storeToBi('BI_03', bi_03_value);
            //endregion

            //# region BI_04 Toilettenbenutzung +
            let bi_04_value = 0;
            if (E2_I_026 === 'E3_I_0081') bi_04_value = 10;
            else if (E2_I_026 === 'E3_I_0080' || E2_I_026 === 'E3_I_0079') bi_04_value = 5;
            else if (E2_I_026 === 'E3_I_0078') bi_04_value = 0;
            resultSum += bi_04_value;
            this.storeToBi('BI_04', bi_04_value);
            //endregion

            //region BI_05 Baden/Duschen +
            let bi_05_value = 0;

            if (E2_I_010 === 'E3_I_0110') bi_05_value = 5;
            resultSum += bi_05_value;
            this.storeToBi('BI_05', bi_05_value);
            //endregion

            //region BI_06 Aufstehen und Gehen +
            let bi_06_value = 0;
            if (E2_I_001 === 'E3_I_0004') bi_06_value = 15;
            else if (E2_I_001 === 'E3_I_0003') bi_06_value = 10;
            else if (E2_I_001 === 'E3_I_0002') bi_06_value = 5;
            else if (E2_I_001 === 'E3_I_0001') bi_06_value = 0;
            resultSum += bi_06_value;
            this.storeToBi('BI_06', bi_06_value);
            //endregion

            //region BI_07 Treppen auf- und absteigen +
            let bi_07_value = 0;
            if (E2_I_001 === 'E3_I_0004') bi_07_value = 10;
            else if (E2_I_001 === 'E3_I_0003') bi_07_value = 5;
            if (E2_I_001 === 'E3_I_0002' || E2_I_001 === 'E3_I_0001') bi_07_value = 0;
            resultSum += bi_07_value;
            this.storeToBi('BI_07', bi_07_value);
            //endregion

            //region BI_08 An- und Auskleiden +
            let bi_08_value = 0;
            let E2_I_011_int = this.getAssessmentOptionOrdinalValue(assessment, 'E2_I_011');
            /* switch (E2_I_011) {
                case 'E3_I_0115':
                    E2_I_011_int = 4;
                    break;
                case 'E3_I_0114':
                    E2_I_011_int = 3;
                    break;
                case 'E3_I_0113':
                    E2_I_011_int = 2;
                    break;
                case 'E3_I_0112':
                    E2_I_011_int = 1;
                    break;
            } */

            let E2_I_012_int = this.getAssessmentOptionOrdinalValue(assessment, 'E2_I_012');
            /* switch (E2_I_012)
            {
                case 'E3_I_0120':
                    E2_I_012_int = 4;
                    break;
                case 'E3_I_0119':
                    E2_I_012_int = 3;
                    break;
                case 'E3_I_0118':
                    E2_I_012_int = 2;
                    break;
                case 'E3_I_0117':
                    E2_I_012_int = 1;
                    break;
            } */

            let sum_011_012 = E2_I_012_int + E2_I_011_int;

            if (E2_I_011 === 'E3_I_0115' && E2_I_012 === 'E3_I_0114') bi_08_value = 10;
            else if (sum_011_012 > 4 && sum_011_012 < 8) bi_08_value = 5;
            else if (sum_011_012 > 0 && sum_011_012 < 5) bi_08_value = 0;
            resultSum += bi_08_value;
            this.storeToBi('BI_08', bi_08_value);
            //endregion

            //region BI_09 Stuhlkontinenz +
            let bi_09_value = 0;

            if ((E2_I_027 === 'E3_I_0086' && E2_I_030 === 'E3_I_0094') || (E2_I_026 === 'E3_I_0081' && E2_I_030 === 'E3_I_0095')) bi_09_value = 10;
            else if ((E2_I_027 === 'E3_I_0085' && E2_I_030 === 'E3_I_0094') ||
                (['E3_I_0080', 'E3_I_0079', 'E3_I_0078'].indexOf(E2_I_026) > -1 && E2_I_030 === 'E3_I_0095')) {
                bi_09_value = 5;
            }

            resultSum += bi_09_value;
            this.storeToBi('BI_09', bi_09_value);
            //endregion

            //region BI_10 Harnkontinenz +
            let bi_10_value: number;

            switch (E2_I_025) {
                case 'E3_I_0076':
                    bi_10_value = 10;
                    break;
                case 'E3_I_0075':
                    bi_10_value = 5;
                    break;
                default:
                    bi_10_value = 0;
                    break;
            }

            resultSum += bi_10_value;
            this.storeToBi('BI_10', bi_10_value);
            //endregion

            //region store the calculated BI
            let bi11Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "BI_11", true);
            if (bi11Item) bi11Item.answer = [{valueInteger: resultSum}];
            //endregion

            if (ConfigService.Debug) console.debug('[calculateBarthelIndex] finished with result-sum of: ' + resultSum);
            return resultSum;
        } catch (e) {
            console.warn("[calculateBarthelIndex] Could not calculate BI because of error: " + e.message);
            return NaN;
        }
    }

    /**
     * Calculate the extended BarthelIndex
     * @param patient - the patient to calculate the BiEx for
     * @param assessment - the assessment to take the data from
     * @param anamnesis - the anamnesis to take the data from
     * @param aBiExResponse - the BiEx QuestionnaireResponse to store the values to
     */
    public calculateBartheldIndexEx(patient: PatientItem, assessment: any, anamnesis: any, aBiExResponse: any): number {
        if (ConfigService.Debug) console.debug('[calculateBartheldIndexEx] started');

        //region do some checking ....
        if (!assessment) {
            let msg = "No Assessment. Aborting";
            console.warn(msg);
            return NaN;
        }

        this.biExResponse = aBiExResponse;

        if (!this.biExResponse) {
            let msg = "No BI-Ex Document. Aborting";
            console.warn(msg);
            return NaN;
        }

        if (this.biExResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
            let msg = "BiEx-Status is not in-progress. Returning stored value..";
            console.warn(msg);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.biExResponse, "EBI_07");
            if (item) {
                let ebi07Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                return parseInt(ebi07Value);
            } else {
                return NaN;
            }
        }

        // ensure a structural correct response with all answers
        this.biExQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(QuestionnaireService.__listResult.QBarthelIndexExId);

        Fhir.Questionnaire.EnsureStructuredResponse(this.biExQuestionnaire, this.biExResponse);
        Fhir.Questionnaire.SetDefaultValues(this.biExQuestionnaire, this.biExResponse, undefined);
        //endregion

        //region used response vars
        let E2_I_082 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_082'); // Informationen verarbeiten - war: Kenntnisse erwerben, 06_03, dann 06_15
        let E2_I_063 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_063'); // Sich mitteilen E2_I_063, war 07_09
        let E2_I_033 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_033'); // Orientierung (Person, Ort, Zeit, Situation) E2_I_033, war 06_02
        //let E2_I_065 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_065'); // Merkmale herausfordernden Verhaltens E2_I_065, war 07_06
        let E2_I_069 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_069'); // Alltagskompetenz (4 -  Jahre) E2_I_069, war 06_05
        let E2_I_062 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'E2_I_062'); // Sehen E2_I_062, war 07_08
        //endregion

        let resultSum = 0;
        try {

            //region EBI_01 Verstehen
            let ebi_01_value: number;
            switch (E2_I_082) {
                case 'E3_I_0390':
                    ebi_01_value = 15;
                    break;
                case 'E3_I_0389':
                    ebi_01_value = 10;
                    break;
                case 'E3_I_0388':
                    ebi_01_value = 5;
                    break;
                case 'E3_I_0387':
                default:
                    ebi_01_value = 0;
                    break;
            }
            resultSum += ebi_01_value;
            this.storeToBiEx('EBI_01', ebi_01_value);
            //endregion

            //region EBI_02 sich verständlich machen
            let ebi_02_value = 0;
            if (E2_I_063 === 'E3_I_0274' && (E2_I_082 === 'E3_I_0390' || E2_I_082 === 'E3_I_0389')) ebi_02_value = 15;
            else if ((E2_I_063 === 'E3_I_0274' && (E2_I_082 === 'E3_I_0388' || E2_I_082 === 'E3_I_0387')) || E2_I_063 === 'E3_I_0273') ebi_02_value = 5;
            else if (E2_I_063 === 'E3_I_0272') ebi_02_value = 0;

            resultSum += ebi_02_value;
            this.storeToBiEx('EBI_02', ebi_02_value);
            //endregion

            //region EBI_03 Soziale Interaktion
            let E2_I_065_int = this.getAssessmentOptionOrdinalValue(assessment, 'E2_I_065'); // Merkmale herausforderndes Verhalten
            /* switch (E2_I_065) {
                case 'E3_I_0263':
                    E2_I_065_int = 4;
                    break;
                case 'E3_I_0261':
                    E2_I_065_int = 12;
                    break;
                case 'E3_I_0260':
                    E2_I_065_int = 11;
                    break;
                case 'E3_I_0243':
                    E2_I_065_int = 14
                    break;
                case 'E3_I_0262':
                    E2_I_065_int = 13;
                    break;
            } */

            let ebi_03_value = 0;
            if (E2_I_033 === 'E3_I_0129') ebi_03_value = 15;
            else if ((E2_I_033 === 'E3_I_0128' && E2_I_065_int < 4) || E2_I_033 === 'E3_I_0127') ebi_03_value = 5;

            resultSum += ebi_03_value;
            this.storeToBiEx('EBI_03', ebi_03_value);
            //endregion

            //region EBI_04 Lösen von Alltagsproblemen (steht nicht in den Pdf, Werte aus Murat's Formular entnommen)
            let ebi_04_value = 0;
            if (E2_I_069 === 'E3_I_0283') ebi_04_value = 15;
            else if (E2_I_069 === 'E3_I_0256') ebi_04_value = 5;

            resultSum += ebi_04_value;
            this.storeToBiEx('EBI_04', ebi_04_value); // was missing in #1376
            //endregion

            //region EBI_05 Gedächtnis/Lernfähigkeit/Orientierung
            let ebi_05_value = 0;                                  // 1: keine Fähigkeit
            if (E2_I_082 === 'E3_I_0390') ebi_05_value = 15;       // 4: volle Fähigkeit
            else if (E2_I_082 === 'E3_I_0389') ebi_05_value = 10;  // 3: überwiegend vorhandene Fähigkeit
            else if (E2_I_082 === 'E3_I_0388') ebi_05_value = 5;   // 2: gering vorhandene Fähigkeit
            resultSum += ebi_05_value;

            this.storeToBiEx('EBI_05', ebi_05_value);
            //endregion

            //region EBI_06 Sehen/Neglect
            let ebi_06_value = 0;  // 3?/0
            if (E2_I_062 === 'E3_I_0270') ebi_06_value = 15;    // 4
            else if (E2_I_062 === 'E3_I_0269') ebi_06_value = 10; // 2
            // 3 does not exist in the pdf
            else if (E2_I_062 === 'E3_I_0268') ebi_06_value = 0; // 1

            this.storeToBiEx('EBI_06', ebi_06_value);
            resultSum += ebi_06_value;
            //endregion

            //region store the result from formSum into "Summe" (EBI_07)
            /*let ebi7Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true);
            if (ebi7Item) ebi7Item.answer = [{ valueInteger: formSum }]; // Fhir.QuestionnaireResponse.SetResponseItemValue(Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true), formSum); */
            QuestionnaireResponse.SetResponseItemIntByLinkId(this.biExResponse, 'EBI_07', resultSum);
            //endregion

            if (ConfigService.Debug) {
                console.debug('[calculateBartheldIndexEx] finished with resulting sum of: ', resultSum);
            }

            return resultSum;
        } catch (error) {
            console.warn('Error when calculating EBI:\n' + error.message);
            return NaN;
        }
    }

    /**
     * Calculate Malnutrition (NRS)
     * @param patient - the patient to calculate for
     * @param assessment - the assessment to take values from
     */
    public async calculateNRS(patient: PatientItem, assessment: any): Promise<INrsResult> {
        await this.ensureQuestionnaires(patient);
        let formSetting = ConfigService.FormSettings.find(o => o.route && o.route === 'analysis');
        //let formSettingAnamnesis = ConfigService.FormSettings.find(o => o.route && o.route === 'anamnesis');
        let anamnesis: any;

        anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, this.questionnaireAnamnesis.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);

        let spi = await this.calculateSpi(patient, assessment);
        let v2_3Nrs = new Epa2_3Nrs(patient, assessment, anamnesis, formSetting, spi.sum, this.i18n);
        let result = await v2_3Nrs.go();
        if (result.errorResult < 0) {
            if (ConfigService.Debug) console.debug("[calculateNRS] NRS-Calculation not successful, errorResult: " + result.errorResult);
            if (result.info && ConfigService.Debug) console.debug("[calculateNRS] Additional info:" + result.info);
        }

        return result;
    }

    public calculateVdd(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;

        let vddFields = [
            'E2_I_033', 'E2_I_082', 'E2_I_069' /* 06_05, Alltagskompetenz */, 'E2_I_054',
            'E2_I_053', 'E2_I_064', 'E2_I_065', 'E2_I_042'
        ];

        let sum = -1;

        for (let i = 0; i < vddFields.length; i++) {
            let val = this.getAssessmentOptionOrdinalValue(assessment, vddFields[i]) || 0; //  Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
            if (vddFields[i] === 'E2_I_065') {
                if (val >= 11) {
                    sum += 1;
                }
            } else {
                if (val === 1 || val === 2) {
                    sum += 1;
                }
            }
        }

        if (sum > 1) sum = 1;
        else sum = 0;

        let textValue = "?";
        switch (sum) {
            case 0:
                textValue = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                textValue = this.i18n.tr("risk_high");
                break;
        }

        return {
            text: textValue,
            isRisk: sum > 0,
            field: 'risk_vdd'
        };
    }

    //endregion
// ****************************************************************************************

//region not updated/old Versions aka TODO: convert analyzer functions to new versions
    public calculateFall(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        // let version = 'AC2.2';
        let fields = [
            '01_01', '01_05', '01_06', '01_07', '01_08',
            '04_05',
            '06_02', '06_07',
            '07_08'
        ];

        //region epac 2.3 detection
        if (Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaireAssessment, '04_02')
            || Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaireAssessment, '08_01')
            || Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaireAssessment, '08_02')
        ) {
            // version = 'epaAC2.3';
            fields = [
                '01_01', '01_05', '01_06', '01_07', '01_08',
                '04_02', '04_09', '04_05',
                '06_02', '06_07',
                '07_08',
                '08_01', '08_02'
            ];
        }
        //endregion

//        if (version === 'epaAC2.3'){
//            return this.calcFallepaAC23(patient, assessment);
//        } else {
        let sum: number = -1;

        if (ConfigService.Debug) console.debug(`[calculateFall] started`);
        try {
            for (let i = 0; i < fields.length; i++) {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, fields[i]);
                let value = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
                if (ConfigService.Debug) console.debug(`[calculateFall]: Field "${fields[i]}" has value "${value}"`);

                // if (!isNaN(value) && (value === 1 || value === 2)) {
                if (!isNaN(value) && value <= 2) {
                    if (ConfigService.Debug) console.debug(`[calculateFall]: Item "${fields[i]}" has a value <= 2. Risk detected`);
                    sum = 1;
                    break;
                }
            }

            if (sum < 0) sum = 0;
        } catch (error) {
            console.warn("[calculateFall]: " + error.message || JSON.stringify(error));
        }

        if (ConfigService.Debug) console.debug(`[calculateFall]: sum is ${sum}`);

        let textValue = this.i18n.tr("n_a");
        let isRisk: boolean = false;

        if (sum === 0) {
            textValue = this.i18n.tr("risk_unlikely");
        } else if (sum > 0) {
            textValue = this.i18n.tr("increased_risk");
            isRisk = true;
        }

        let res = {
            text: textValue,
            isRisk: isRisk,
            field: 'risk_sturz'
        };

        if (ConfigService.Debug) console.debug(`[calculateFall]: will return:`, res);

        return res;
//        }
    }

    public calculatePneumo(patient: PatientItem, assessment?: any) {
        let pneuFields = [
            '01_01', '03_10', '06_01',
            '09_01', '09_02', '09_03',
            '09_04', '09_06',
        ];

        if (!assessment) assessment = patient.latestAssessment;
        let sum = -1;

        try {
            for (let i = 0; i < pneuFields.length; i++) {
                let field = pneuFields[i];
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, field);
                let val: string = String(Fhir.QuestionnaireResponse.GetResponseItemValue(item));
                let intVal = Fhir.QuestionnaireResponse.GetKeyValueAsInteger(val);
                if (ConfigService.Debug) console.debug(`[calcPneumo] field "${field}" has value "${intVal}"`);

                if (!isNaN(intVal) && intVal <= 2) {
                    sum = 1;
                    if (ConfigService.Debug) console.debug(`[calcPneumo] field "${field}" <= 2 (${intVal}) Pneumo Risk detected`);
                    break;
                }
            }

            if (sum === -1) sum = 0;
        } catch (e) {
            console.warn(`[calcPneumo] ${e.message || JSON.stringify(e)}`);
        }

        let text = this.i18n.tr("n_a");
        switch (sum) {
            default:
                break;
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
        }

        let result = {
            text: text,
            field: 'risk_pneu',
            sum: sum,
            isRisk: sum >= 1
        };

        if (ConfigService.Debug) console.debug(`[calcPneumo] will return:`, result);

        return result;
    }

    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        // risk_deku_sum
        if (!assessment) assessment = patient.latestAssessment;
        let fields = ['01_01', '01_02', '01_03', '03_03', '04_08', '10_01'];

        if (ConfigService.Debug) console.debug("\n-----\n[calculateRiskDecu] started", assessment, "Fields:", fields);
        let sum = this.getCalcSum(assessment, fields, false);
        if (ConfigService.Debug) console.debug(`[calculateRiskDecu] Resulting Sum: ${sum}`);

        if (sum < 17) {
            if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is < 17 -> Result will be 1 or 2");
            if (sum >= 13 && sum <= 16) {
                if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is 13-16 -> sum = 1");
                sum = 1;
            } else {
                if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is NOT 13-16 -> sum = 2");
                sum = 2;
            }
        } else {
            if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is >= 17 -> sum = 0");
            sum = 0;
        }

        let text = this.i18n.tr("not_available");
        switch (sum) {
            default:
            case -1:
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
            case 2:
                text = this.i18n.tr("risk_high");
                break;
        }

        let result = {
            sum: sum,
            field: 'risk_deku',
            text: text
        };

        if (ConfigService.Debug) console.debug("finished Decu calculation with result", result, "------\n\n");

        return result;
    }

    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) return undefined;

        let v04_01Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_01');
        let v04_01: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_01Item);

        let v04_02Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_02');
        let v04_02: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_02Item);

        let v04_09Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_09');
        let v04_09: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_09Item);

        let v07_06Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '07_06');
        let v07_06: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v07_06Item);

        let contincenceValue = -1;
        let inkontinenzText = "";
        if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 4)) {
            inkontinenzText = this.i18n.tr("kontinenz");
            contincenceValue = 0;
        } else if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 1)) {
            inkontinenzText = this.i18n.tr("unabhaengigerreichtekontinenz");
            contincenceValue = 1;
        } else if ((v04_01 != 4) && (v04_02 == 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("abhaengigerreichtekontinenz");
            contincenceValue = 2;
        } else if ((v04_01 == 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("unabhaengigkompensierteinkontinenz");
            contincenceValue = 3;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && ([4, 11, 13].indexOf(v07_06) > -1)) {
            inkontinenzText = this.i18n.tr("abhaengigkompensierteinkontinenz");
            contincenceValue = 4;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && (v07_06 == 12 || v07_06 == 14)) {
            contincenceValue = 5;
            inkontinenzText = this.i18n.tr("nichtkompensierteinkontinenz");
        }

        return {
            field: 'kontinenz',
            text: inkontinenzText,
            value: contincenceValue
        };
    }

    //endregion

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);

        let aConfig = ConfigService.GetFormSettings('assessment');
        if (aConfig) {
            let aQ = QuestionnaireService.GetQuestionnaireByNameDirect(aConfig.questionnaireName);
            if (aQ) {
                if (!assessment || (assessment && assessment.questionnaire && assessment.questionnaire.reference.indexOf('/' + aQ.id) === -1) || this.assessmentName !== aQ.name) {
                    if (aConfig && aQ) {
                        assessment = QuestionnaireService.GetLatestResponseOfType(patient, aQ.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                        if (assessment) {
                            QuestionnaireService.__listResult.QAssessmentId = aQ.id;
                            this.assessmentName = aQ.name;
                        }
                    }
                }
            }
        }

        if (ConfigService.Debug) 
            console.debug('This is Analyzer Version: ' + this._version + ' using AssessmentName: ' + this.assessmentName);
        await this.ensureQuestionnaires(patient);

        if (!assessment) {
            if (ConfigService.Debug) console.warn("No Assessment in ", patient);
            return;
        }

        // let raBackup = NitTools.Clone(patient.riskAssessment);
        // check for the riskAssessment
        if (updateRiskAssessment && !patient.currentRisks) {
            let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, this.questionnaireAnamnesis.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, anamnesis);
        }

        let resultPKMS = await this.calculateSpi(patient, assessment);
        let resultDecu = this.calculateRiskDecu(patient, assessment);
        let resultPneumo = this.calculatePneumo(patient, assessment);
        let resultFall = this.calculateFall(patient, assessment);
        let resultVDD = this.calculateVdd(patient, assessment);
        let resultKontinenz = this.calculateIncontinenceProfile(patient, assessment);
        let resultNRS = await this.calculateNRS(patient, assessment);

        let bi = this.calculateBarthelIndex(patient, assessment, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndex"));
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, this.questionnaireAnamnesis.id, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        let biEx = this.calculateBartheldIndexEx(patient, assessment, anamnesis, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndexEx"));
        let icdItems = await this.icdService.getICDCodes(patient);
        let showMissingFieldsWarning = false;
        icdItems.filter(o => o.test());

        //region set pkms value
        // PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment);
        await PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment, patient.careLevelText, patient.careLevelColor, resultPKMS.sum);

        let itemPkmsText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_sum_text', true);
        if (itemPkmsText) itemPkmsText.answer = [{valueString: resultPKMS.textSum}];
        else {
            console.warn("No 'risk_spi_sum_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsRText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_risk_text', true);
        if (itemPkmsRText) itemPkmsRText.answer = [{valueString: resultPKMS.textRisk}];
        else {
            console.warn("No 'risk_spi_risk_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdSum, true);
        if (itemPkmsSum) {
            itemPkmsSum.answer = [
                {valueInteger: resultPKMS.sum}
            ];
            itemPkmsSum.linkId = resultPKMS.linkIdSum;
        } else {
            console.warn(`No '${resultPKMS.linkIdSum}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPkmsRisk = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdIsRisk, true);
        if (itemPkmsRisk) {
            itemPkmsRisk.answer = [
                {valueBoolean: resultPKMS.isRisk}
            ];
            itemPkmsRisk.linkId = resultPKMS.linkIdIsRisk;
        } else {
            console.warn(`No '${resultPKMS.linkIdIsRisk}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        //endregion

        //region Decubitus
        // WO HIN SPEICHERN?
        let itemDecubitus = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field, true);
        if (itemDecubitus) {
            itemDecubitus.answer = [
                {valueBoolean: resultDecu.sum >= 1}
            ];
        } else {
            console.warn(`No '${resultDecu.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemDecuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_sum", true);
        if (itemDecuSum) {
            itemDecuSum.answer = [
                {valueInteger: resultDecu.sum}
            ];
        } else {
            console.warn(`No '${resultDecu.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let decuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_text", true);
        if (decuText) {
            decuText.answer = [{valueString: resultDecu.text}];
        } else {
            console.warn(`No '${resultDecu.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region pneumo
        let itemPneu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field, true);
        if (itemPneu) {
            itemPneu.answer = [
                {valueBoolean: resultPneumo.isRisk}
            ];
            itemPneu.linkId = resultPneumo.field;
        } else {
            console.warn(`No '${resultPneumo.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + '_sum', true);
        if (itemPneuSum) {
            itemPneuSum.answer = [
                {valueInteger: resultPneumo.sum}
            ];
        } else {
            console.warn(`No '${resultPneumo.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + "_text", true);
        if (itemPneuText) {
            itemPneuText.answer = [{valueString: resultPneumo.text}];
            itemPneuSum.linkId = resultPneumo.field + '_sum';
        } else {
            console.warn(`No '${resultPneumo.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region fall
        let itemFall = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field, true);
        if (itemFall) {
            itemFall.answer = [
                {
                    valueBoolean: resultFall.isRisk
                }
            ];
            itemFall.linkId = resultFall.field;
        } else {
            console.warn(`No '${resultFall.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemFallText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field + "_text", true);
        if (itemFallText) {
            itemFallText.answer = [{
                valueString: resultFall.text
            }];
        } else {
            console.warn(`No '${resultFall.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region vdd
        let itemVdd = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field, true);
        if (itemVdd) {
            itemVdd.answer = [
                {
                    valueBoolean: resultVDD.isRisk
                }
            ];
            itemVdd.linkId = resultVDD.field;
        } else {
            console.warn(`No '${resultVDD.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemVddText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field + "_text", true);
        if (itemVddText) {
            itemVddText.answer = [{valueString: resultVDD.text}];
        } else {
            console.warn(`No '${resultVDD.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region continence
        let itemKonti = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field, true);
        if (itemKonti) {
            itemKonti.answer = [{valueInteger: resultKontinenz.value}];
        } else {
            console.warn(`No '${resultKontinenz.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemKontiText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field + "_text", true);
        if (itemKontiText) {
            itemKontiText.answer = [{valueString: resultKontinenz.text}];
        } else {
            console.warn(`No '${resultKontinenz.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        // TODO: DOUBLE CHECK the assigned values an especially the storage of the NRS items
        //region NRS
        if (resultNRS) {
            let nrsSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdSum, true);
            if (nrsSumItem) {
                nrsSumItem.answer = [
                    {
                        valueInteger: resultNRS.riskSum
                    }
                ];
                nrsSumItem.linkId = resultNRS.linkIdSum;
            } else {
                console.warn(`No '${resultNRS.linkIdSum}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdIsRisk, true);
            if (nrsRiskItem) {
                nrsRiskItem.answer = [
                    {valueInteger: resultNRS.errorResult}
                ];
                nrsRiskItem.linkId = resultNRS.linkIdIsRisk;
            } else {
                console.warn(`No '${resultNRS.linkIdIsRisk}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItemText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_nrs_warning', true);
            if (nrsRiskItemText) {
                nrsRiskItemText.answer = [{valueString: resultNRS.riskText}];
            } else {
                console.warn(`No 'risk_nrs_warning' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }
        } else {
            console.warn('No NRS-Result generated');
        }
        //endregion

        //region BI
        let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BI_11', true);
        if (biItem) {
            biItem.answer = [
                {valueInteger: bi}
            ];
        } else {
            console.warn(`No 'BI_11' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region BI-EX
        let biExItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'EBI_07', true);
        if (biExItem) {
            biExItem.answer = [
                {valueInteger: biEx}
            ];
        } else {
            console.warn(`No 'EBI_07' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //endregion

        //region if this is the latestAssessment update everything and even riskassessment
        if (patient.latestAssessment && patient.latestAssessment.id === assessment.id) {
            let spiSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdSum, true);
            if (spiSumItem) spiSumItem.answer = [{valueInteger: resultPKMS.sum}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(spiSumItem, resultPKMS.sum, resultPKMS.textSum);

            let spiRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdIsRisk, true);
            Fhir.QuestionnaireResponse.SetResponseItemCoding(spiRiskItem, String(resultPKMS.isRisk), resultPKMS.textRisk);
            await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

            //region update RiskAssessment
            if (updateRiskAssessment) {
                CIRiskAssessment.ApplyRisksFromAssessment(patient.currentRisks, assessment);

                this.updateRiskAssessmentPrediction(resultPKMS, patient)
                    .updateRiskAssessmentPrediction(
                        {
                            linkIdSum: resultDecu.field,
                            sum: resultDecu.sum,
                            textSum: resultDecu.text
                        }, patient)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultPneumo, patient, true)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultFall, patient, false)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultVDD, patient, false);
                if (resultKontinenz) {
                    this.updateRiskAssessmentPrediction({
                        linkIdSum: resultKontinenz.field,
                        sum: resultKontinenz.value,
                        textSum: String(resultKontinenz.text)
                    }, patient);
                }

                if (resultNRS) {
                    this.updateRiskAssessmentPrediction(
                        {
                            linkIdIsRisk: resultNRS.linkIdIsRisk,
                            isRisk: resultNRS.riskSum > 0,
                            riskText: resultNRS.riskText,
                            linkIdSum: resultNRS.linkIdSum,
                            sum: resultNRS.errorResult,
                            textSum: resultNRS.riskText
                        }, patient);
                }

                this.updateRiskAssessmentPrediction({
                    linkIdSum: "BarthelIndex",
                    sum: String(bi),
                    textSum: String(bi)
                }, patient)
                    .updateRiskAssessmentPrediction({
                        linkIdSum: "BarthelIndexEx",
                        sum: String(biEx),
                        textSum: String(biEx)
                    }, patient);

                if (showMissingFieldsWarning) {
                    let msg = "There are missing fields in the Assessment.<br />Please read the Console Warnings and update the Questionnaire.";
                    // RuntimeInfo.ShowInfo(msg);
                    console.warn(msg);
                }

                if (anamnesis && assessment) {
                    if ((["completed", "ameneded"].indexOf(anamnesis.status) > -1) && (["completed", "ameneded"].indexOf(assessment.status) > -1)) {
                        patient.currentRisks.status = "final";
                    }
                }

                await this.updateRiskAssessmentValues(patient, assessment, patient.currentRisks);
                if (/*!CIRiskAssessment.AreEqual(patient.riskAssessment, raBackup) && */ storeRiskAssessment) {
                    if (ConfigService.Debug) console.debug("Updating Riskassessment", patient.currentRisks);
                    try {
                        patient.currentRisks = <any>await this.fhirService.update(patient.currentRisks);
                    } catch (error) {
                        console.warn(error.message || JSON.stringify(error));
                    }
                }
            }
            //endregion
        }
        //endregion

        await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

        this.patientChangeNotifier.notify(patient);

        return {
            pkmsIsRisk: resultPKMS.isRisk,
            pkmsSum: resultPKMS.sum
        };
    }
}

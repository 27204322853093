import * as Fhir from "../../../../resources/classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import * as moment from "moment";
import {IFormSetting} from "../../../classes/IFormSettings";
import {QuestionnaireService} from "../../QuestionnaireService";
import {fhirEnums} from "../../../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {ConfigService} from "../../ConfigService";
import {PatientItem} from "../../../classes/Patient/PatientItem";

export class Epa2_2Nrs {

    private readonly patient: PatientItem;
    private assessment: any;
    private anamnesis: any;
    private formSettings: IFormSetting;
    /** value indicating whether NRS should be calculated even if BMI influencing factors are set. could be specified in settings.NRSCalcEvenIfBMIFactorsAreSet */
    private readonly NRSCalcEvenIfBMIFactorsAreSet;
    /** value indicating whether BMI influencing factors are set */
    private BMIFactorsAreSet = false;
    /** value specifying the value calculation period for malnutrition in days. recommended: 3 or 7 days. could be specified in settings.nrsCalculationPeriod */
    private readonly nrsCalculationPeriod;
    private i18n: I18N;
    private readonly spi: number;
    private readonly QuestionnaireAnamanesis: any;
    private outOfCalculationPeriod: boolean = false;

    constructor(patient: PatientItem, assessment: any, anamnesis: any, formSettings: IFormSetting, spi: number, i18n: I18N) {
        this.patient = patient;
        this.assessment = assessment;
        this.anamnesis = anamnesis;
        this.formSettings = formSettings;
        this.i18n = i18n;
        this.spi = spi;

        if (typeof this.formSettings.settings.NRSCalcEvenIfBMIFactorsAreSet === "boolean") {
            this.NRSCalcEvenIfBMIFactorsAreSet = this.formSettings.settings.NRSCalcEvenIfBMIFactorsAreSet;
        } else {
            this.NRSCalcEvenIfBMIFactorsAreSet = false;
        }

        if (typeof this.formSettings.settings.nrsCalculationPeriod === "number") {
            this.nrsCalculationPeriod = this.formSettings.settings.nrsCalculationPeriod;
        } else {
            this.nrsCalculationPeriod = 3;
        }

        this.QuestionnaireAnamanesis = QuestionnaireService.GetQuestionnaireDirect(QuestionnaireService.__listResult.QAnamnesisId);
    }

    anaValue(linkId: string) {
        return Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(this.anamnesis, linkId);
    }

    assValue(linkId: string) {
        return Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(this.assessment, linkId);
    }

    private SPIValuesValid(assessment: any): boolean {
        let result = true;
        let fields = ['01_01', '05_01', '05_02', '05_03', '05_04', '03_01', '03_05', '04_01', '04_03'];
        fields.forEach(field => {
            let fieldValue = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, field, NaN);
            if (fieldValue === NaN || [1, 2, 3, 3, 4].indexOf(fieldValue) === -1) {
                if (ConfigService.Debug) {
                    console.warn(`Invalid fieldValue for field "${field}" ("${String(fieldValue)}")`);
                }

                result = false;
                return false;
            }
        });

        //#region when AC 2.3, LTC 1.0 or KIDS 2.0 field '06_15' exists and needs to be checked too. Determine by existence of field
        let ana_06_15 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '06_15', false);
        if (typeof ana_06_15 !== "undefined") {
            //if (ConfigService.Debug) console.debug("Field '06_15' exists. Checking value too");
            let ana_06_15_int = Fhir.QuestionnaireResponse.GetResponseItemValueInt(ana_06_15);
            if (ana_06_15_int === NaN || ana_06_15_int < 0 || ana_06_15_int > 4) {
                //if (ConfigService.Debug) console.debug(`06_15 is invalid ("${String(ana_06_15_int)}")`, ana_06_15);
                result = false;
            } else {
                //if (ConfigService.Debug) console.debug(`06_15 seems to have valid value (${ana_06_15_int})`);
            }
        }
        //#endregion

        return result;
    }

    /** Subprocess1 - calculate "Nahrungsmenge" */
    private subprocess1(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            value: NaN,
            success: true,
            messages: []
        };

        let lastSavedDateAnamnesis = moment(this.anamnesis.meta?.lastUpdated);
        if (!this.assessment) this.assessment = this.patient.latestAssessment;

        let lastSavedDateAssessment = moment(this.assessment.meta?.lastUpdated);
        let dayCount = lastSavedDateAnamnesis.diff(lastSavedDateAssessment, "d");
        if (dayCount < -1) dayCount *= -1;
        dayCount -= 1;

        if (dayCount > this.nrsCalculationPeriod) {
            result.success = false;
            result.messages.push(this.i18n.tr('nrs_not_in_calculation_period'));
            this.outOfCalculationPeriod = true;
            return result;
        }

        let ana_00_17 = this.anaValue('00_17'); // Nahrungsmenge
        if (['00_17_04', '00_17_03', '00_17_02', '00_17_01'].indexOf(ana_00_17) === -1) {
            result.success = false;
            result.messages.push(this.i18n.tr('nrs_food_quantity_invalid'));

            return result;
        }

        let assessmentList = QuestionnaireService.GetResponsesOfType(this.patient, QuestionnaireService.__listResult.QAssessmentId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        let value1: number = 0;
        let value2: number = 0;
        let LMSum: number = 0;
        let j = 0;
        let lTempValue: number = 0;
        for (let i = assessmentList.length; i > 0; i--) {
            let historicAssessment = assessmentList[i - 1];
            let valueItem1 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(historicAssessment, "03_02", false);
            let valueItem2 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(historicAssessment, "03_13", false);
            value1 = valueItem1 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem1) : 0;
            value2 = valueItem2 ? Fhir.QuestionnaireResponse.GetResponseItemValueInt(valueItem2) : 0;
            LMSum = value1 + value2;
            if (LMSum > 4) LMSum = 4;
            lTempValue += LMSum;
            j++;

            if (j >= this.nrsCalculationPeriod) break; // max 3
        }

        if (assessmentList.length > 3) {
            lTempValue = lTempValue / (3 /* assessments */ + 1 /* admission */);
        } else {
            lTempValue = lTempValue / (assessmentList.length + 1); // < 3 assessments + 1 admission
        }

        if (lTempValue <= 1.5) {
            result.value = 3;
        } else if (lTempValue >= 1.6 && lTempValue <= 2.4) {
            result.value = 2;
        } else if (lTempValue >= 2.5 && lTempValue <= 3.4) {
            result.value = 1;
        }

        result.success = true;
        result.messages = undefined;

        return result;
    }

    /** Subprocess2 - calculate "BMI/Allg.Zust." */
    private subprocess2(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        if (this.spi >= 32) {
            result.value = 0;
        } else {
            let tmp_ana_00_05 = this.anaValue('00_05');
            let ana_00_05 = typeof tmp_ana_00_05 !== 'undefined' ? parseFloat(tmp_ana_00_05) : 0;
            if (!ana_00_05) {
                if (!this.NRSCalcEvenIfBMIFactorsAreSet) {
                    result.messages.push(this.i18n.tr('nrs_00_05_invalid_value'));
                    result.success = false;
                    return result;
                } else {
                    this.BMIFactorsAreSet = true;
                }
            }

            if (ana_00_05 < 18.5) { // Ja
                result.value = 3;
            } else if (ana_00_05 >= 18.5 && ana_00_05 <= 20.5) {
                result.value = 2;
            } else result.value = 1;
        }

        return result;
    }

    /** Subprocess3 - calculate "Gewichtsverlust" */
    private subprocess3(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        let ana_00_04 = this.anaValue('00_04');
        switch (ana_00_04) {
            case '00_04_00':
                result.value = 0;
                break;
            case '00_04_01':
                result.value = 1;
                break;
            case '00_04_02':
                result.value = 2;
                break;
            case '00_04_03':
                result.value = 3;
                break;
            default:
                result.success = false;
                result.messages.push(this.i18n.tr('nrs_00_04_invalid_value'));
                result.value = NaN;
                break;
        }

        return result;
    }

    /** SubProcess4 - calculate "erhöhter Bedarf/Stressmetabolismus" */
    private subprocess4(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        let ass_03_12 = this.assValue('03_12');
        switch (ass_03_12) {
            case '03_12_01':
                result.value = 3;
                break;
            case '03_12_02':
                result.value = 2;
                break;
            case '03_12_03':
                result.value = 1;
                break;
            case '03_12_04':
                result.value = 0;
                break;
            default:
                result.value = NaN;
                result.success = false;
                result.messages.push(this.i18n.tr('nrs_03_12_invalid_value'));
        }

        return result;
    }

    /** SubProcess 5 - calculate "Alter und abschließende Berechnung" */
    private subprocess5(): INrsSubProcessResult {
        let result: INrsSubProcessResult = {
            messages: [],
            success: true,
            value: 0
        };

        if (this.patient.years && this.patient.years >= 70) result.value = 1;

        return result;
    }

    public go(): INrsResult {
        //if (ConfigService.Debug) console.debug('epa2.2 NRS test has started');
        //#region preflight check
        //#endregion

        let result: INrsResult = {
            linkIdSum: "risk_nrs_sum",
            linkIdIsRisk: "risk_nrs_calc",
            errorResult: undefined,  // riskOutCome =  sub1 + sub2 + sub3 + sub4 + sub5
            riskText: this.i18n.tr('nrs_risk_na'),     // message describing the result (n/a, yes, unlikely)
            riskSum: NaN, // 0 or 1
            isRisk: undefined
        };

        let ana_00_04 = this.anaValue('00_04');
        if (['00_04_00', '00_04_01', '00_04_02', '00_04_03'].indexOf(ana_00_04) === -1) {
            result.errorResult = -99;
            result.info = `00_04 has invalid value ("${String(ana_00_04)}")`;
            return result;
        }

        if (!this.SPIValuesValid(this.assessment)) {
            result.errorResult = -98;
            result.info = `At least one SPI-field has an invalid value`;
            return result;
        }

        // check BMI values
        let ana_00_05 = this.anaValue('00_05');

        let ana_00_0635 = undefined;
        if (this.QuestionnaireAnamanesis) {
            let test_00_35 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.QuestionnaireAnamanesis, '00_35');
            if (test_00_35) {
                ana_00_0635 = this.anaValue('00_35');
            } else {
                ana_00_0635 = this.anaValue('00_06');
                if (typeof ana_00_0635 === "undefined") {
                    ana_00_0635 = this.anaValue('ana_00_06');
                }

                //if (ConfigService.Debug) console.debug('epa2.3 Field 00_35 not found, using epa2.2 field 00_06 instead');
            }
        }

        if (!ana_00_05) {
            result.info = '00_05 (BMI Value) not found in Anamnesis';
            result.errorResult = -97;
            return result;
        }

        this.BMIFactorsAreSet = (typeof ana_00_0635 !== "undefined") && (ana_00_0635 !== '00_06_04' && ana_00_0635 !== '00_35_04');
        if (this.BMIFactorsAreSet && !this.NRSCalcEvenIfBMIFactorsAreSet) {
            result.info = '00_06 (BMI Factors) has been set in Anamnesis. NRSCalcEvenIfBMIFactorsAreSet=false';
            result.errorResult = -96;
            return result;
        }

        /*if (this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet && ConfigService.Debug)
            console.debug('BMIFactors are set, but NRSCalcEvenIfBMIFactorsAreSet is set to true. So continuing NRS Calc'); */

        let subProcess1 = this.subprocess1(); // Nahrungsmenge
        if (!subProcess1.success) {
            result.info = subProcess1.messages ? subProcess1.messages.join(',\n') : 'Subprocess1 failed';
            result.errorResult = -95;
        }

        let subProcess2 = this.subprocess2(); // BMI / Allg.zust.
        if (!subProcess2.success) {
            result.info = subProcess2.messages ? subProcess2.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -94;
        }

        let subProcess3 = this.subprocess3(); // Gesichtsverlust
        if (!subProcess3.success) {
            result.info = subProcess3.messages ? subProcess3.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -93;
        }

        let subProcess4 = this.subprocess4(); // Gesichtsverlust
        if (!subProcess4.success) {
            result.info = subProcess4.messages ? subProcess4.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -92;
        }

        let subProcess5 = this.subprocess5(); // Gesichtsverlust
        if (!subProcess5.success) {
            result.info = subProcess5.messages ? subProcess5.messages.join(',\n') : 'Subprocess2 failed';
            result.errorResult = -91;
        }

        if (!subProcess1.success || !subProcess2.success || !subProcess3.success || !subProcess4.success || !subProcess5.success) {
            result.errorResult = -90;
            result.riskSum = NaN;
            result.isRisk = false;
            result.riskSum = 0;

            return result;
        }

        let nrsProcess1_3Max = Math.max(subProcess1.value, subProcess2.value, subProcess3.value);
        result.riskSum = nrsProcess1_3Max + subProcess4.value + subProcess5.value;
        result.isRisk = result.riskSum >= 3;
        result.errorResult = 0;
        result.info = this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet ? this.i18n.tr('nrs_risk_addition') : undefined;
        result.riskText = this.i18n.tr(result.isRisk ? 'nrs_risk_yes' : 'nrs_risk_unlikely');

        // place info that bmi factors are set but customer wants to calculate nrs anyway
        if (result.isRisk && this.BMIFactorsAreSet && this.NRSCalcEvenIfBMIFactorsAreSet) {
            result.riskText += ` <span class="nrs-additional-warning">${this.i18n.tr('nrs_risk_addition')}</span>`;
        }

        // place info why this can not be calculated (out of calculation period)
        if (this.outOfCalculationPeriod) {
            result.riskText += ` <span class="nrs-additional-warning">${this.i18n.tr('nrs_not_in_calculation_period')}</span>`;
        }

        /*if (ConfigService.Debug) {
            console.debug(`NRS Results:\n
                        ---------------------
                        SubProcess1: ${subProcess1.value} Pt
                        SubProcess2: ${subProcess2.value} Pt
                        SubProcess3: ${subProcess3.value} Pt
                        SubProcess4: ${subProcess4.value} Pt
                        SubProcess5: ${subProcess5.value} Pt
                        ---------------------
                        Max SP1-3  : ${nrsProcess1_3Max} Pt
                        =====================
                        NRS-Result:  ${result.riskSum} Pt
                        IsRisk:  ${result.isRisk}`);
        } */
        result.riskSum = result.isRisk ? 1 : 0;

        /* from TModelRiskNRS:
            this.riskOutCome = sub1 + sub2 + sub3 + sub4 + sub5;
            if (this.riskOutCome >= 3) {
                this.riskCheckOutcome = 1;
                this.resultMessage = translations.translate("nrs_risk_yes");
            } else {
                this.riskCheckOutcome = 0;
                this.resultMessage = translations.translate("nrs_risk_unlikely");
            }
        */

        //if (ConfigService.Debug) console.debug('V2 NRS Calc finished. Returning:', result);

        return result;
    }

    /*
    public calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList) {
        try {
            if (!assessment) assessment = patient.latestAssessment;
            if (!assessment) {
                let msg = "No Assessment for Patient found";
                console.warn(msg);

                return undefined;
            }

            let lModel = new TModelRiskNRS(patient, qList);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_sum", true);
            // if (item) Fhir.QuestionnaireResponse.SetResponseItemValue(item, 0);
            item.answer = [{valueInteger: 0}];

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
            if (item) item.answer = [{valueInteger: 0}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, 0);

            item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_warning", true);
            if (item) item.answer = [{valueString: this.i18n.tr("not_available")}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, this.i18n.tr("not_available"));

            if (lModel.check()) {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_sum", true);
                if (item) item.answer = [{valueInteger: lModel.riskCheckOutcome}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.riskCheckOutcome);

                item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
                if (item) item.answer = [{valueInteger: lModel.riskCheckOutcome}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.riskOutCome);

                item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_warning", true);
                if (item) item.answer = [{valueString: lModel.resultMessage}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(item, lModel.resultMessage);
            } else {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "risk_nrs_calc", true);
                if (item) item.answer = [{valueInteger: -1}];
            }

            return {
                linkIdSum: "risk_nrs_sum",
                linkIdIsRisk: "risk_nrs_calc",
                errorResult: lModel.riskOutCome,
                riskText: lModel.resultMessage,
                riskSum: lModel.riskCheckOutcome
            };
        } catch (error) {
            console.warn(error.message);
            return undefined;
        }
    } */
}

export interface INrsSubProcessResult {
    success: boolean;
    messages: string[];
    value: number;
}

export interface INrsResult {
    linkIdSum: string;
    linkIdIsRisk: string;
    errorResult: number;  // riskOutCome =  sub1 + sub2 + sub3 + sub4 + sub5
    riskText: string;     // message describing the result (n/a, yes, unlikely)
    riskSum: number; // 0 or 1
    isRisk: boolean;
    info?: string;
}

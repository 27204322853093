import {IRiskItem} from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, QuestionnaireResponse} from "resources/classes/FhirModules/Fhir";
import {AnalyzerClass} from '../AnalyzerClass';
import {NitTools} from 'resources/classes/NursitTools';
import {IQuestionnaireList, QuestionnaireService} from '../QuestionnaireService';
import {fhirEnums} from "../../classes/fhir-enums";
import {AnalyzeService} from '../analyzeService';
import {INrsResult, Epa2_2Nrs} from "./epa2_2_Subs/epa2_2-nrs"
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {ConfigService} from "../ConfigService";
import {PatientItem} from "../../classes/Patient/PatientItem";

export class AnalyzerEpaKIDS22 extends AnalyzerClass {
    _version = 'epaKids2.2';
    description: string = "This Analyzer version EPA 2.2 for KIDS";
    assessmentName: string = 'CareITAssessmentEPA_KIDS';
    anamnesisName: string = 'CareITKIDS_Anamnesis';
    needToStoreFlags: boolean = true;

    //#region new Versions:
    private storeOption(questionnaire: any, target: any, linkId: string, value: number) {
        let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
        let _code = linkId + '_' + NitTools.ToString(value, 2, '0');
        let _display = String(value);

        // try to find the correct option (because we should not trust the casings)
        if (questionnaireItem.option) {
            let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code && o.valueCoding.code.endsWith('_' + String(value)));
            if (option) {
                _code = option.valueCoding.code;
                if (option.valueCoding.display) _display = option.valueCoding.display;
            }
        }

        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(target, linkId, true);
        item.answer = [
            {
                valueCoding: {
                    code: _code,
                    display: _display
                }
            }
        ];
    }

    private biResponse;
    private biQuestionnaire;

    private storeToBi(linkId: string, value: number) {
        this.storeOption(this.biQuestionnaire, this.biResponse, linkId, value);
    }

    private biExquestionnaire;
    private biExResponse;

    private storeToBiEx(linkId: string, value: number) {
        this.storeOption(this.biExquestionnaire, this.biExResponse, linkId, value);
    }

    public calculateBarthelIndex(patient: PatientItem, assessment: any, biResponse: any): number {
        let resultSum = 0;
        this.biResponse = biResponse;
        this.biQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect('CareITBarthelIndex');

        try {
            //#region do some checks first ...
            if (!biResponse) {
                let msg = "No BarthelIndex-Response given";
                console.warn(msg);
                return NaN;
            }

            if (biResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
                let msg = "BarthelIndex is not in state in-progress. Returning stored value";
                console.debug(msg);
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "BI_11");
                if (item) {
                    let bi11Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                    let int = parseInt(bi11Value);
                    return int;
                } else {
                    msg = "No previous stored value found for BI_11 in response. Aborting";
                    return NaN;
                }
            }
            //#endregion

            //#region ensure a valid and structured BartelIndex Response with default values set
            Fhir.Questionnaire.EnsureStructuredResponse(this.biQuestionnaire, biResponse);
            Fhir.Questionnaire.SetDefaultValues(this.biQuestionnaire, biResponse, undefined);
            //#endregion

            //#region BI_01 - Essen
            let ass_03_01 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "03_01", '-');
            let ass_03_18 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "03_18", '-');
            let bi_01_value = 0;
            if (ass_03_01 === '03_01_04') {
                bi_01_value = 10;
            } else if (
                ((ass_03_01 === '03_01_03' || ass_03_01 === '03_01_02' || ass_03_01 === '03_01_01') && ass_03_18 === '03_18_01')
                ||
                (ass_03_01 === '03_01_03' && ass_03_18 === '03_18_04')
            ) {
                bi_01_value = 5;
            } else if ((ass_03_01 === '03_01_02' || ass_03_01 === '03_01_01') && ass_03_18 === '03_18_04') // already default, but to be complete like the PDF
            {
                bi_01_value = 0;
            }

            // add bi01 value to formsum
            resultSum += bi_01_value;

            // store bi 01
            this.storeToBi('BI_01', bi_01_value);
            //#endregion

            //#region BI_02 - Aufsetzen/Umsetzen
            let bi_02_value = 0;
            let ass_01_01_int = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '01_01');
            let ass_01_01 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '01_01');
            let ass_01_02 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '01_02');
            if (ass_01_02 === '01_02_04') bi_02_value = 15;
            else if (ass_01_02 === '01_02_03') bi_02_value = 10;
            else if (ass_01_02 === '01_02_02' && ass_01_01_int > 1) bi_02_value = 5;
            else if ((ass_01_02 === '01_02_02' && ass_01_01 === '01_01_01') || (ass_01_02 === '01_02_01')) bi_02_value = 0; // already default, but to be complete like the PDF
            resultSum += bi_02_value;
            this.storeToBi('BI_02', bi_02_value);
            //#endregion

            //#region BI_03 - Sich waschen
            let bi_03_value = 0;
            let ass_05_01 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '05_01');
            if (ass_05_01 === '05_01_04') bi_03_value = 5;
            resultSum += bi_03_value;
            this.storeToBi('BI_03', bi_03_value);
            //#endregion

            //# region BI_04 Toilettenbenutzung
            let bi_04_value = 0;
            let ass_04_03 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '04_03');
            if (ass_04_03 === '04_03_04') bi_04_value = 10;
            else if (ass_04_03 === '04_03_03' || ass_04_03 === '04_03_02') bi_04_value = 5;
            else if (ass_04_03 === '04_03_01') bi_04_value = 0;
            resultSum += bi_04_value;
            this.storeToBi('BI_04', bi_04_value);
            //#endregion

            //#region BI_05 Baden/Duschen
            let bi_05_value = 0;
            let ass_05_02 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '05_02');
            if (ass_05_02 === '05_02_04') bi_05_value = 5;
            resultSum += bi_05_value;
            this.storeToBi('BI_05', bi_05_value);
            //#endregion

            //#region BI_06 Aufstehen und Gehen
            let bi_06_value = 0;
            if (ass_01_01 === '01_01_04') bi_06_value = 15;
            else if (ass_01_01 === '01_01_03') bi_06_value = 10;
            else if (ass_01_01 === '01_01_02') bi_06_value = 5;
            else if (ass_01_01 === '01_01_01') bi_06_value = 0;
            resultSum += bi_06_value;
            this.storeToBi('BI_06', bi_06_value);
            //#endregion

            //#region BI_07 Treppen auf- und absteigen
            let bi_07_value = 0;
            if (ass_01_01 === '01_01_04') bi_07_value = 10;
            else if (ass_01_01 === '01_01_03') bi_07_value = 5;
            if (ass_01_01 === '01_01_02' || ass_01_01 === '01_01_01') bi_07_value = 0;
            resultSum += bi_07_value;
            this.storeToBi('BI_07', bi_07_value);
            //#endregion

            //#region BI_08 An- und Auskleiden
            let bi_08_value = 0;
            let ass_05_03 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '05_03');
            let ass_05_03_int = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '05_03');
            let ass_05_04 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '05_04');
            let ass_05_04_int = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '05_04');
            let sum_03_and_04 = ass_05_04_int + ass_05_03_int;

            if (ass_05_03 === '05_03_04' && ass_05_04 === '05_04_04') bi_08_value = 10;
            else if (sum_03_and_04 > 4 && sum_03_and_04 < 8) bi_08_value = 5;
            else if (sum_03_and_04 > 0 && sum_03_and_04 < 5) bi_08_value = 0;
            resultSum += bi_08_value;
            this.storeToBi('BI_08', bi_08_value);
            //#endregion

            //#region BI_09 Stuhlkontinenz
            let bi_09_value = 0;
            let ass_04_04 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '04_04');
            let ass_04_10 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '04_10');
            if ((ass_04_04 === '04_04_04' && ass_04_10 === '04_10_04') || (ass_04_03 === '04_03_04' && ass_04_10 === '04_10_01')) bi_09_value = 10;
            else if ((ass_04_04 === '04_04_03' && ass_04_10 === '04_10_04') ||
                (['04_03_03', '04_03_02', '04_03_01'].indexOf(ass_04_03) > -1 && ass_04_10 === '04_10_01')) {
                bi_09_value = 5;
            }
            resultSum += bi_09_value;
            this.storeToBi('BI_09', bi_09_value);
            //#endregion

            //#region BI_10 Harnkontinenz
            let bi_10_value = 0;
            let ass_04_02 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '04_02');
            switch (ass_04_02) {
                case '04_02_04':
                    bi_10_value = 10;
                    break;
                case '04_02_03':
                    bi_10_value = 5;
                    break;
                default:
                    bi_10_value = 0;
                    break;
            }
            resultSum += bi_10_value;
            this.storeToBi('BI_10', bi_10_value);
            //#endregion

            //#region store the calculated BI
            let bi11Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, "BI_11", true);
            if (bi11Item) bi11Item.answer = [{valueInteger: resultSum}];
            //#endregion

            return resultSum;
        } catch (e) {
            console.warn("Could not calculate BI because of error: " + e.message);
            return NaN;
        }
    }

    public calculateBartheldIndexEx(patient: PatientItem, assessment: any, anamnesis: any, aBiExResponse: any): number {
        //#region do some checking ....
        if (ConfigService.Debug) {
            console.debug('\n-----\n[calculateBartheldIndexEx] started');
        }

        if (!assessment) {
            let msg = "No Assessment. Aborting";
            console.warn(msg);
            return NaN;
        }

        this.biExResponse = aBiExResponse;

        if (!this.biExResponse) {
            let msg = "No BI-Ex Document. Aborting";
            console.warn(msg);
            return NaN;
        }

        if (this.biExResponse.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
            let msg = "BiEx-Status is not in-progress. Returning stored value..";
            console.warn(msg);

            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.biExResponse, "EBI_07");
            if (item) {
                let ebi07Value = Fhir.QuestionnaireResponse.GetResponseItemValue(item);
                let int = parseInt(ebi07Value);
                return int;
            } else {
                return NaN;
            }
        }

        // ensure a structural correct response with all answers
        this.biExquestionnaire = QuestionnaireService.GetQuestionnaireDirect(QuestionnaireService.__listResult.QBarthelIndexExId);

        Fhir.Questionnaire.EnsureStructuredResponse(this.biExquestionnaire, this.biExResponse);
        Fhir.Questionnaire.SetDefaultValues(this.biExquestionnaire, this.biExResponse, undefined);
        //#endregion

        let resultSum = 0;
        try {
            //#region EBI_01 Verstehen
            let ass_06_03 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '06_03');
            let ebi_01_value = 0;
            switch (ass_06_03) {
                case '06_03_04':
                    ebi_01_value = 15;
                    break;
                case '06_03_03':
                    ebi_01_value = 10;
                    break;
                case '06_03_02':
                    ebi_01_value = 5;
                    break;
                case '06_03_01':
                default:
                    ebi_01_value = 0;
                    break;
            }
            resultSum += ebi_01_value;
            this.storeToBiEx('EBI_01', ebi_01_value);
            //#endregion

            //#region EBI_02 sich verständlich machen
            let ass_07_09 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '07_09');
            let ebi_02_value = 0;
            if (ass_07_09 === '07_09_04' && (ass_06_03 === '06_03_04' || ass_06_03 === '06_03_03')) ebi_02_value = 15;
            else if ((ass_07_09 === '07_09_04' && (ass_06_03 === '06_03_02' || ass_06_03 === '06_03_01')) || ass_07_09 === '07_09_02') ebi_02_value = 5;
            else if (ass_07_09 === '07_09_01') ebi_02_value = 0;
            resultSum += ebi_02_value;
            this.storeToBiEx('EBI_02', ebi_02_value);
            //#endregion

            //#region EBI_03 Soziale Interaktion
            let ass_07_06_int = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(assessment, '07_06');
            let ass_06_02 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '06_02');
            let ebi_03_value = 0;
            if (ass_06_02 === '06_02_04') ebi_03_value = 15;
            else if ((ass_06_02 === '06_02_03' && ass_07_06_int < 4) || ass_06_02 === '06_02_02') ebi_03_value = 5;
            resultSum += ebi_03_value;
            this.storeToBiEx('EBI_03', ebi_03_value);
            //#endregion

            //#region EBI_04
            let ebi_04_value = 0;
            let ass_06_05 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '06_05');
            if (ass_06_05 === '06_05_04') ebi_04_value = 15;
            else if (ass_06_05 === '06_05_03') ebi_04_value = 5;
            resultSum += ebi_04_value;
            this.storeToBiEx('EBI_04', ebi_04_value); // was missing in #1376
            //#endregion

            //#region EBI_05 Gedächtnis/Lernfähigkeit/Orientierung
            let ebi_05_value = 0;
            if (ass_06_03 === '06_03_04') ebi_05_value = 15;
            else if (ass_06_03 === '06_03_03') ebi_05_value = 10;
            else if (ass_06_03 === '06_03_02') ebi_05_value = 5;
            resultSum += ebi_05_value;
            this.storeToBiEx('EBI_05', ebi_05_value);
            //#endregion

            //#region EBI_06 Sehen/Neglect
            let ebi_06_value = 0;
            let ass_07_08 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, '07_08');
            if (ass_07_08 === '07_08_04') ebi_06_value = 15;
            else if (ass_07_08 === '07_08_02') ebi_06_value = 10;
            // 5 has cell saying "Keine Ausleitung möglich" in the PDF (#1138, attachment "E Bi Berechnung neu.pdf") - what ever that means!
            else if (ass_07_08 === '07_08_01') ebi_06_value = 0;
            this.storeToBiEx('EBI_06', ebi_06_value);
            resultSum += ebi_06_value;
            //#endregion

            //#region ... and store the result from formSum into "Summe" (EBI_07)
            /*let ebi7Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true);
            if (ebi7Item) ebi7Item.answer = [{ valueInteger: formSum }]; // Fhir.QuestionnaireResponse.SetResponseItemValue(Fhir.QuestionnaireResponse.GetResponseItemByLinkId(FBarthelIndexExBuilder.Assessment, "EBI_07", true), formSum); */
            QuestionnaireResponse.SetResponseItemIntByLinkId(this.biExResponse, 'EBI_07', resultSum);
            //#endregion

            if (ConfigService.Debug) {
                console.debug('\n-----\n[calculateBartheldIndexEx] finished:', resultSum);
            }

            return resultSum;
        } catch (error) {
            console.warn('Error when calculating EBI:\n' + error.message);
            return NaN;
        }
    }

    public async calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList): Promise<INrsResult> {
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
        let form = ConfigService.FormSettings.find(o => o.route && o.route === 'analysis');
        let spi = await this.calculateSpi(patient, assessment);
        let v2Nrs = new Epa2_2Nrs(patient, assessment, anamnesis, form, spi.sum, this.i18n);
        let result = v2Nrs.go();
        if (result.errorResult < 0) {
            if (ConfigService.Debug) console.debug("[calculateNRS] NRS-Calculation not successful, errorResult: " + result.errorResult);
            if (result.info && ConfigService.Debug) console.debug("[calculateNRS] Additional info:" + result.info);
        }

        return result;
    }

    public calculateFall(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let version = 'AC2.2';
        let fields = [
            '01_01', '01_05', '01_06', '01_07', '01_08',
            '04_05',
            '06_02', '06_07',
            '07_08'
        ];

        //#region epac 2.3 detection
        let questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment.questionnaire);
        if (Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, '04_02')
            || Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, '08_01')
            || Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, '08_02')
        ) {
            version = 'epaAC2.3';
            fields = [
                '01_01', '01_05', '01_06', '01_07', '01_08',
                '04_02', '04_09', '04_05',
                '06_02', '06_07',
                '07_08',
                '08_01', '08_02'
            ];
        }
        //#endregion

//        if (version === 'epaAC2.3'){
//            return this.calcFallepaAC23(patient, assessment);
//        } else {
        let sum: number = -1;

        if (ConfigService.Debug) console.debug(`[calculateFall] started`);
        try {
            for (let i = 0; i < fields.length; i++) {
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, fields[i]);
                let value = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
                if (ConfigService.Debug) console.debug(`[calculateFall]: Field "${fields[i]}" has value "${value}"`);

                // if (!isNaN(value) && (value === 1 || value === 2)) {
                if (!isNaN(value) && value <= 2) {
                    if (ConfigService.Debug) console.debug(`[calculateFall]: Item "${fields[i]}" has a value <= 2. Risk detected`);
                    sum = 1;
                    break;
                }
            }

            if (sum < 0) sum = 0;
        } catch (error) {
            console.warn("[calculateFall]: " + error.message || JSON.stringify(error));
        }

        if (ConfigService.Debug) console.debug(`[calculateFall]: sum is ${sum}`);

        let textValue = this.i18n.tr("n_a");
        let isRisk: boolean = false;

        if (sum === 0) {
            textValue = this.i18n.tr("risk_unlikely");
        } else if (sum > 0) {
            textValue = this.i18n.tr("increased_risk");
            isRisk = true;
        }

        let res = {
            text: textValue,
            isRisk: isRisk,
            field: 'risk_sturz'
        };

        if (ConfigService.Debug) console.debug(`[calculateFall]: will return:`, res);

        return res;
//        }
    }

    public calculatePneumo(patient: PatientItem, assessment?: any) {
        let pneuFields = [
            '01_01', '03_10', '06_01',
            '09_01', '09_02', '09_03',
            '09_04', '09_06',
        ];

        if (!assessment) assessment = patient.latestAssessment;
        let sum = -1;

        try {
            for (let i = 0; i < pneuFields.length; i++) {
                let field = pneuFields[i];
                let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, field);
                let val: string = String(Fhir.QuestionnaireResponse.GetResponseItemValue(item));
                let intVal = Fhir.QuestionnaireResponse.GetKeyValueAsInteger(val);
                if (ConfigService.Debug) console.debug(`[calcPneumo] field "${field}" has value "${intVal}"`);

                if (!isNaN(intVal) && intVal <= 2) {
                    sum = 1;
                    if (ConfigService.Debug) console.debug(`[calcPneumo] field "${field}" <= 2 (${intVal}) Pneumo Risk detected`);
                    break;
                }
            }

            if (sum === -1) sum = 0;
        } catch (e) {
            console.warn(`[calcPneumo] ${e.message || JSON.stringify(e)}`);
        }

        let text = this.i18n.tr("n_a");
        switch (sum) {
            default:
                break;
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
        }

        let result = {
            text: text,
            field: 'risk_pneu',
            sum: sum,
            isRisk: sum >= 1
        };

        if (ConfigService.Debug) console.debug(`[calcPneumo] will return:`, result);

        return result;
    }

    //#endregion

    //#region not updated/old Versions:
    public async calculateSpi(patient: PatientItem, from?: any): Promise<IRiskItem> {
        /** -= IMPORTANT  =-
         * we don't update the Questionnaire values in here,
         * because it is constantly called from assessment-view,
         * which handles the Updates */

        patient.careLevel = undefined;
        patient.careLevelString = undefined;
        if (!patient.questionnaireResponses) patient.questionnaireResponses = [];

        let riskItem: IRiskItem = {
            name: "SPI",
            linkIdSum: "risk_spi_sum",
            linkIdIsRisk: "risk_spi",
            hasError: false,
            error: undefined,
            sum: undefined,
            textSum: '',
            textRisk: '',
            buttonText: this.i18n.tr("not_aviable"),
            title: this.i18n.tr("riskofpkms"),
            showButton: false,
            careLevel: undefined,
            versorgung: false
        };

        try {
            let fields = ['01_01', /* not included, even if the title says SPI...: '01_02', */ '05_01', '05_02', '05_03', '05_04', '03_01', '03_05', '04_01', '04_03', '06_03'];
            let assessment: any = from || patient.latestAssessment;

            if (!assessment) return riskItem;

            riskItem.sum = this.getCalcSum(assessment, fields, true);
            riskItem.isRisk = riskItem.sum <= 25; // AnalyzeService.PkmsRelevanceStart;

            riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
            riskItem.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);

            let str = this.i18n.tr("analyse_info_text");
            str = str.replace("%PATIENT%", patient.display)
                .replace("%CARELEVEL%", riskItem.textSum)
                .replace("%SPI%", riskItem.sum.toString());

            riskItem.textRisk = str;

            if (riskItem.isRisk) {
                riskItem.buttonText = this.i18n.tr("inform_social_services");
                riskItem.showButton = true;
            } else {
                riskItem.textRisk = this.i18n.tr("noobservablerisk");
            }

            riskItem.textSum = Fhir.Tools.SpiToString(riskItem.sum);
            riskItem.versorgung = riskItem.sum <= 32;
        } catch (e) {
            riskItem.hasError = true;
            riskItem.error = e.message;
        }

        patient.pkms_relevant = riskItem.sum <= AnalyzeService.PkmsRelevanceStart;
        patient.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);
        patient.careLevelString = Fhir.Tools.SpiToString(riskItem.sum);

        return riskItem;
    }

    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        // risk_deku_sum
        if (!assessment) assessment = patient.latestAssessment;
        let fields = ['01_01', '01_02', '01_03', '03_03', '04_08', '10_01'];

        if (ConfigService.Debug) console.debug("\n-----\n[calculateRiskDecu] started", assessment, "Fields:", fields);
        let sum = this.getCalcSum(assessment, fields, false);
        if (ConfigService.Debug) console.debug(`[calculateRiskDecu] Resulting Sum: ${sum}`);

        if (sum < 17) {
            if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is < 17 -> Result will be 1 or 2");
            if (sum >= 13 && sum <= 16) {
                if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is 13-16 -> sum = 1");
                sum = 1;
            } else {
                if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is NOT 13-16 -> sum = 2");
                sum = 2;
            }
        } else {
            if (ConfigService.Debug) console.debug("[calculateRiskDecu] risk sum is >= 17 -> sum = 0");
            sum = 0;
        }

        let text = this.i18n.tr("not_available");
        switch (sum) {
            default:
            case -1:
            case 0:
                text = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                text = this.i18n.tr("increased_risk");
                break;
            case 2:
                text = this.i18n.tr("risk_high");
                break;
        }

        let result = {
            sum: sum,
            field: 'risk_deku',
            text: text
        };

        if (ConfigService.Debug)
            console.debug("finished Decu calculation with result", result, "------\n\n");

        return result;
    }

    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) return undefined;

        let v04_01Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_01');
        let v04_01: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_01Item);

        let v04_02Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_02');
        let v04_02: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_02Item);

        let v04_09Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '04_09');
        let v04_09: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v04_09Item);

        let v07_06Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, '07_06');
        let v07_06: number = Fhir.QuestionnaireResponse.GetResponseItemValueInt(v07_06Item);

        let contincenceValue = -1;
        let inkontinenzText = "";
        if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 4)) {
            inkontinenzText = this.i18n.tr("kontinenz");
            contincenceValue = 0;
        } else if ((v04_01 == 4) && (v04_02 == 4) && (v04_09 == 1)) {
            inkontinenzText = this.i18n.tr("unabhaengigerreichtekontinenz");
            contincenceValue = 1;
        } else if ((v04_01 != 4) && (v04_02 == 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("abhaengigerreichtekontinenz");
            contincenceValue = 2;
        } else if ((v04_01 == 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1))) {
            inkontinenzText = this.i18n.tr("unabhaengigkompensierteinkontinenz");
            contincenceValue = 3;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && ([4, 11, 13].indexOf(v07_06) > -1)) {
            inkontinenzText = this.i18n.tr("abhaengigkompensierteinkontinenz");
            contincenceValue = 4;
        } else if ((v04_01 != 4) && (v04_02 != 4) && ((v04_09 == 4) || (v04_09 == 1)) && (v07_06 == 12 || v07_06 == 14)) {
            contincenceValue = 5;
            inkontinenzText = this.i18n.tr("nichtkompensierteinkontinenz");
        }

        return {
            field: 'kontinenz',
            text: inkontinenzText,
            value: contincenceValue
        };
    }

    public calculateVdd(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;

        let vddFields = [
            '06_02', '06_03', '06_05', '06_06',
            '06_07', '07_10', '07_06', '08_02'
        ];

        let sum = -1;

        for (let i = 0; i < vddFields.length; i++) {
            let field = vddFields[i];
            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, field);
            let val = Fhir.QuestionnaireResponse.GetResponseItemValueInt(item);
            if (field === '07_06') {
                if ([11, 12, 13, 14].indexOf(val) > -1) {
                    sum += 1;
                }
            } else {
                if (val === 1 || val === 2) {
                    sum += 1;
                }
            }
        }

        if (sum > 1) sum = 1;
        else sum = 0;

        let textValue = "?";
        switch (sum) {
            case 0:
                textValue = this.i18n.tr("risk_unlikely");
                break;
            case 1:
                textValue = this.i18n.tr("risk_high");
                break;
        }

        return {
            text: textValue,
            isRisk: sum > 0,
            field: 'risk_vdd'
        };
    }

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        if (ConfigService.Debug) 
            console.debug('This is Analyzer Version: ' + this._version + ' using AssessmentName: ' + this.assessmentName);
        await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);

        let qList = await QuestionnaireService.GetQuestionnaireIds();

        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) assessment = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        if (!assessment) {
            if (ConfigService.Debug) console.warn("No Assessment in ", patient);
            return;
        }

        // let raBackup = NitTools.Clone(patient.riskAssessment);
        // check for the riskAssessment
        if (updateRiskAssessment && !patient.currentRisks) {
            let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, anamnesis);
        }

        let resultPKMS = await this.calculateSpi(patient, assessment);
        let resultDecu = this.calculateRiskDecu(patient, assessment);
        let resultPneumo = this.calculatePneumo(patient, assessment);
        let resultFall = this.calculateFall(patient, assessment);
        let resultVDD = this.calculateVdd(patient, assessment);
        let resultKontinenz = this.calculateIncontinenceProfile(patient, assessment);
        let resultNRS = await this.calculateNRS(patient, assessment, qList);

        let bi = this.calculateBarthelIndex(patient, assessment, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndex"));
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        let biEx = this.calculateBartheldIndexEx(patient, assessment, anamnesis, await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, "BarthelIndexEx"));
        let icdItems = await this.icdService.getICDCodes(patient);
        let showMissingFieldsWarning = false;
        icdItems = icdItems.filter(o => o.test());
        /*        if (ConfigService.Debug) {
                    console.info("TODO:");
                    console.info("[Fhir.Analyse] - ICDS:", icdItems);
                } */

        //#region set pkms value
        await PatientItem.UpdateCareLevel(patient, resultPKMS.careLevel, assessment, patient.careLevelText, patient.careLevelColor, resultPKMS.sum);

        let itemPkmsText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_sum_text', true);
        if (itemPkmsText) itemPkmsText.answer = [{valueString: resultPKMS.textSum}];
        else {
            console.warn("No 'risk_spi_sum_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsRText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_spi_risk_text', true);
        if (itemPkmsRText) itemPkmsRText.answer = [{valueString: resultPKMS.textRisk}];
        else {
            console.warn("No 'risk_spi_risk_text' item found in Assessment-Response");
            showMissingFieldsWarning = true;
        }

        let itemPkmsSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdSum, true);
        if (itemPkmsSum) {
            itemPkmsSum.answer = [
                {valueInteger: resultPKMS.sum}
            ];
            itemPkmsSum.linkId = resultPKMS.linkIdSum;
        } else {
            console.warn(`No '${resultPKMS.linkIdSum}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPkmsRisk = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPKMS.linkIdIsRisk, true);
        if (itemPkmsRisk) {
            itemPkmsRisk.answer = [
                {valueBoolean: resultPKMS.isRisk}
            ];
            itemPkmsRisk.linkId = resultPKMS.linkIdIsRisk;
        } else {
            console.warn(`No '${resultPKMS.linkIdIsRisk}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        //#endregion

        //#region Decubitus
        // WO HIN SPEICHERN?
        let itemDecu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field, true);
        if (itemDecu) {
            itemDecu.answer = [
                {valueBoolean: resultDecu.sum >= 1}
            ];
        } else {
            console.warn(`No '${resultDecu.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemDecuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_sum", true);
        if (itemDecuSum) {
            itemDecuSum.answer = [
                {valueInteger: resultDecu.sum}
            ];
        } else {
            console.warn(`No '${resultDecu.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let decuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultDecu.field + "_text", true);
        if (decuText) {
            decuText.answer = [{valueString: resultDecu.text}];
        } else {
            console.warn(`No '${resultDecu.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region pneumo
        let itemPneu = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field, true);
        if (itemPneu) {
            itemPneu.answer = [
                {valueBoolean: resultPneumo.isRisk}
            ];
            itemPneu.linkId = resultPneumo.field;
        } else {
            console.warn(`No '${resultPneumo.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuSum = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + '_sum', true);
        if (itemPneuSum) {
            itemPneuSum.answer = [
                {valueInteger: resultPneumo.sum}
            ];
        } else {
            console.warn(`No '${resultPneumo.field + "_sum"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemPneuText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultPneumo.field + "_text", true);
        if (itemPneuText) {
            itemPneuText.answer = [{valueString: resultPneumo.text}];
            itemPneuSum.linkId = resultPneumo.field + '_sum';
        } else {
            console.warn(`No '${resultPneumo.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region fall
        let itemFall = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field, true);
        if (itemFall) {
            itemFall.answer = [
                {
                    valueBoolean: resultFall.isRisk
                }
            ];
            itemFall.linkId = resultFall.field;
        } else {
            console.warn(`No '${resultFall.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemFallText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultFall.field + "_text", true);
        if (itemFallText) {
            itemFallText.answer = [{
                valueString: resultFall.text
            }];
        } else {
            console.warn(`No '${resultFall.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region vdd
        let itemVdd = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field, true);
        if (itemVdd) {
            itemVdd.answer = [
                {
                    valueBoolean: resultVDD.isRisk
                }
            ];
            itemVdd.linkId = resultVDD.field;
        } else {
            console.warn(`No '${resultVDD.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemVddText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultVDD.field + "_text", true);
        if (itemVddText) {
            itemVddText.answer = [{valueString: resultVDD.text}];
        } else {
            console.warn(`No '${resultVDD.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region continence
        let itemKonti = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field, true);
        if (itemKonti) {
            itemKonti.answer = [{valueInteger: resultKontinenz.value}];
        } else {
            console.warn(`No '${resultKontinenz.field}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }

        let itemKontiText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultKontinenz.field + "_text", true);
        if (itemKontiText) {
            itemKontiText.answer = [{valueString: resultKontinenz.text}];
        } else {
            console.warn(`No '${resultKontinenz.field + "_text"}' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        // TODO: DOUBLE CHECK the assigned values an especially the storage of the NRS items
        //#region NRS
        if (resultNRS) {
            let nrsSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdSum, true);
            if (nrsSumItem) {
                nrsSumItem.answer = [
                    {
                        valueInteger: resultNRS.riskSum
                    }
                ];
                nrsSumItem.linkId = resultNRS.linkIdSum;
            } else {
                console.warn(`No '${resultNRS.linkIdSum}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, resultNRS.linkIdIsRisk, true);
            if (nrsRiskItem) {
                nrsRiskItem.answer = [
                    {valueInteger: resultNRS.errorResult}
                ];
                nrsRiskItem.linkId = resultNRS.linkIdIsRisk;
            } else {
                console.warn(`No '${resultNRS.linkIdIsRisk}' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }

            let nrsRiskItemText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'risk_nrs_warning', true);
            if (nrsRiskItemText) {
                nrsRiskItemText.answer = [{valueString: resultNRS.riskText}];
            } else {
                console.warn(`No 'risk_nrs_warning' item found in Assessment-Response`);
                showMissingFieldsWarning = true;
            }
        } else {
            console.warn('No NRS-Result generated');
        }
        //#endregion

        //#region BI
        let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BI_11', true);
        if (biItem) {
            biItem.answer = [
                {valueInteger: bi}
            ];
        } else {
            console.warn(`No 'BI_11' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region BI-EX
        let biExItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'EBI_07', true);
        if (biExItem) {
            biExItem.answer = [
                {valueInteger: biEx}
            ];
        } else {
            console.warn(`No 'EBI_07' item found in Assessment-Response`);
            showMissingFieldsWarning = true;
        }
        //#endregion

        //#region if this is the latestAssessment update everything and even riskassessment
        if (patient.latestAssessment && patient.latestAssessment.id === assessment.id) {
            let spiSumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdSum, true);
            if (spiSumItem) spiSumItem.answer = [{valueInteger: resultPKMS.sum}]; // Fhir.QuestionnaireResponse.SetResponseItemValue(spiSumItem, resultPKMS.sum, resultPKMS.textSum);

            let spiRiskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, resultPKMS.linkIdIsRisk, true);
            Fhir.QuestionnaireResponse.SetResponseItemCoding(spiRiskItem, String(resultPKMS.isRisk), resultPKMS.textRisk);
            await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

            //#region update RiskAssessment
            if (updateRiskAssessment) {
                CIRiskAssessment.ApplyRisksFromAssessment(patient.currentRisks, assessment);

                this.updateRiskAssessmentPrediction(resultPKMS, patient)
                    .updateRiskAssessmentPrediction(
                        {
                            linkIdSum: resultDecu.field,
                            sum: resultDecu.sum,
                            textSum: resultDecu.text
                        }, patient)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultPneumo, patient, true)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultFall, patient, false)
                    .updateRiskAssessmentPredictionFromSimpleResult(resultVDD, patient, false);
                if (resultKontinenz) {
                    this.updateRiskAssessmentPrediction({
                        linkIdSum: resultKontinenz.field,
                        sum: resultKontinenz.value,
                        textSum: String(resultKontinenz.text)
                    }, patient);
                }

                if (resultNRS) {
                    this.updateRiskAssessmentPrediction(
                        {
                            linkIdIsRisk: resultNRS.linkIdIsRisk,
                            isRisk: resultNRS.riskSum > 0,
                            riskText: resultNRS.riskText,
                            linkIdSum: resultNRS.linkIdSum,
                            sum: resultNRS.errorResult,
                            textSum: resultNRS.riskText
                        }, patient);
                }

                this.updateRiskAssessmentPrediction({
                    linkIdSum: "BarthelIndex",
                    sum: String(bi),
                    textSum: String(bi)
                }, patient)
                    .updateRiskAssessmentPrediction({
                        linkIdSum: "BarthelIndexEx",
                        sum: String(biEx),
                        textSum: String(biEx)
                    }, patient);

                if (showMissingFieldsWarning) {
                    let msg = "There are missing fields in the Assessment.<br />Please read the Console Warnings and update the Questionnaire.";
                    // RuntimeInfo.ShowInfo(msg);
                    console.warn(msg);
                }

                if (anamnesis && assessment) {
                    if ((["completed", "ameneded"].indexOf(anamnesis.status) > -1) && (["completed", "ameneded"].indexOf(assessment.status) > -1)) {
                        patient.currentRisks.status = "final";
                    }
                }

                await this.updateRiskAssessmentValues(patient, assessment, patient.currentRisks);
                if (/*!CIRiskAssessment.AreEqual(patient.riskAssessment, raBackup) && */ storeRiskAssessment) {
                    if (ConfigService.Debug) console.debug("Updating Riskassessment", patient.currentRisks);
                    try {
                        let updatedRA = <any>await this.fhirService.update(patient.currentRisks);
                        patient.currentRisks = updatedRA;
                    } catch (error) {
                        console.warn(error.message || JSON.stringify(error));
                    }
                }
            }
            //#endregion
        }
        //#endregion

        await PatientItem.UpdateCareLevel(patient, Fhir.Tools.SpiToCareLevel(resultPKMS.sum));

        this.patientChangeNotifier.notify(patient);

        return {
            pkmsIsRisk: resultPKMS.isRisk,
            pkmsSum: resultPKMS.sum
        };
    }

    //#endregion
}
